import {isArray, isString} from "lodash"
import { all, takeEvery, getContext, call, select, put, take } from "redux-saga/effects"
import { authenticate, CompositeSignature, currentUser, CurrentUserObject } from "@onflow/fcl"
import I18n from "i18n-js"
import { Action, ActionForType, ActionType } from "@root/actions"
import { ApiSagaContext } from "@root/api"
import openViaPOST from "@root/helper/openViaPOST"
import { loginPath, logoutPath } from "@root/routes"
import { connectWalletPopup, simpleErrorPopup } from "@root/components/Popup"

const doLogout = () => {
  window.location.href = logoutPath
}

const MESSAGE_TO_SIGN = "Please log me in, kthxbye."

const login = function*(action: ActionForType<"ACCOUNT_LOGIN">) {
  const initialUser: CurrentUserObject = yield call(currentUser.snapshot)
  console.log("LOGIN", "initial current user:", initialUser)

  // if not already logged in, start authentication flow with Dapper
  if (!isString(initialUser?.addr)) {
    const authenticatedUser = yield call(authenticate)
    console.log("LOGIN", "current user after login:", authenticatedUser)

    // sanity check: if everything went well, we now have a user address
    if (!isString(authenticatedUser?.addr)) {
      console.warn("LOGIN", "authentication failed, user:", authenticatedUser)
      return
    }

    // next we force the user to perform a new user interaction, because otherwise
    // the browser might block the popup which is created by Dapper.
    // note: this popup is not needed, if the user was already logged in!
    yield put<Action>({
      type: "POPUP_SHOW",
      data: {
        popup: connectWalletPopup()
      }
    })
    const popupResult: ActionForType<"POPUP_CLOSE"> = yield take<Action>("POPUP_CLOSE")
    if (popupResult?.data.result.connect !== true) {
      console.warn("LOGIN", "user aborted connect popup")
      return
    }
  }

  const res: CompositeSignature[] | string = yield call(currentUser.signUserMessage, Buffer.from(MESSAGE_TO_SIGN).toString("hex"))

  // detect aborted signing messages
  if (typeof res === "string") {
    // the user might have only declined the dialog, no further UI needed,
    // otherwise we should show a failure popup
    if (res.startsWith("Declined")) {
      console.log("LOGIN", "signature has been declined with error:", res)
    } else {
      console.error("LOGIN", "signature failed with error:", res)
      yield put<Action>({
        type: "POPUP_SHOW",
        data: {
          popup: simpleErrorPopup(res, I18n.t("generic.ok"))
        }
      })
    }
    return
  }

  // detect incomplete signatures
  if (!isArray(res) || res.length === 0) {
    console.error("LOGIN", "incomplete signature:", res)
    console.error("LOGIN", "signature failed with error:", res)
    yield put<Action>({
      type: "POPUP_SHOW",
      data: {
        popup: simpleErrorPopup(
          I18n.t("account_section.connect_wallet.signing_failed"),
          I18n.t("generic.ok")
        )
      }
    })
    return
  }

  // if this step is reached everything should be fine for our server
  const signature = res[0]
  console.warn({signature})
  const api: ApiSagaContext = yield getContext("api")
  openViaPOST(loginPath, api.authToken(), signature)
}

const logout = function*(action: ActionForType<"ACCOUNT_LOGOUT">) {
  doLogout()
}

const logoutIfConnectedUserNotSameAsSessionUser = function*(action: ActionForType<"ACCOUNT_MAKE_SURE_CONNECTED_USER_IS_SAME_AS_SERVER_THINKS">) {
  const user = yield call(authenticate)
  console.log("AUTO_LOGOUT", "initial current user:", user)
  if (user.addr !== action.data.authToken) {
    doLogout()
  }
}

export function* accountSaga() {
  yield all([
    takeEvery<ActionType>("ACCOUNT_LOGIN", login),
    takeEvery<ActionType>("ACCOUNT_LOGOUT", logout),
    takeEvery<ActionType>("ACCOUNT_MAKE_SURE_CONNECTED_USER_IS_SAME_AS_SERVER_THINKS", logoutIfConnectedUserNotSameAsSessionUser)
  ])
}
