import AccountButton from "@root/components/AccountSection/AccountButton"
import fillWithBabelMarkdown from "@root/helper/fillWithBabelMarkdown"
import { NeoFC } from "@root/types"
import I18n from "i18n-js"
import React from "react"

import "./InfoBox.scss"

export const ICONS = [
  "warning",
  "fulfillmentWarning",
  "fulfilled",
  "notFulfilled",
] as const

export type InfoBoxIcon = typeof ICONS[number]

const InfoBox: NeoFC<{icon: InfoBoxIcon}> = ({icon, children}) => {
  const className = [
    "neo__infoBox",
    `neo__infoBox--${icon}`
  ].join(" ")

  return <div className={className}>
    {children}
  </div>
}

export default InfoBox
