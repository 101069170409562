import { BoardGroupId, BoardId } from "./types"

export const rootPath = "/"
export const logoutPath = "/sessions/logout"
export const loginPath = "/sessions"
export const helpPath = "/help"
export const boardsPath = (boardGroup?: BoardGroupId, boardId?: BoardId) => {
  if (boardGroup && boardId) { return `/boards/${boardGroup}/${boardId}`}
  if (boardGroup) { return `/boards/${boardGroup}` }
  return "/boards"
}
export const howItWorksPath = "/how-it-works"
export const sandboxPath = "/sandbox"
