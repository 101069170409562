import { LandingState, LandingCompState, initialLandingCompState } from "@components/page/Landing/state"
import { BoardsCompState, BoardsState, initialBoardsCompState } from "@root/components/page/Boards/state"
import { InfoPageState } from "@root/components/page/InfoPage/state"
import { initialPopupCompState, PopupCompState } from "@root/components/Popup/state"

export interface UserInfo {
  auth_token: string
  nickname: string
}

interface Generic {
  user?: UserInfo
  eth_nonce?: any
  form_authenticity_token: string
  flow_client_url: string
  wallet_discovery: string
}

export interface State {
  coming_soon?: Boolean
  generic: Generic
  landing?: LandingState
  boards?: BoardsState
  info_page?: InfoPageState
  comp_state: {
    popup: PopupCompState
    landing?: LandingCompState
    boards?: BoardsCompState
  }
}

export const prefillCompStates = (st: State) => {
  st = {...st, comp_state: {  // clone + add generic comp states
    popup: initialPopupCompState
  }}
  // specific view comp states
  if (st.boards) { st.comp_state.boards = initialBoardsCompState }
  if (st.landing) { st.comp_state.landing = initialLandingCompState }
  return st
}

export type ViewState<X extends keyof State> = Required<Pick<State, "generic" | X>>
// export type StateForLandingPage = ViewState<"landing">
