import { spawn } from "redux-saga/effects"

import { landingSaga } from "@root/components/page/Landing/saga"
import { boardsSaga } from "@root/components/page/Boards/saga"
import { accountSaga } from "@root/account/saga"

export default function* mainSaga() {
  yield spawn(landingSaga)
  yield spawn(boardsSaga)
  yield spawn(accountSaga)
}
