import React, { useState } from "react"

import { PageTitle, Block, Note, SectionTitle } from '@root/Sandbox'
import { ContentRow, PageContent } from "@root/ui/Layout"
import Board from "."
import { BENEFIT_TYPES, Board as BoardType, PlayerTokenRequirement, YbeeToken, YbeeTokenRequirement } from "@root/types"
import FulfillmentInfo from "./FulfillmentInfo"
import _ from "lodash"
import Benefits, { BenefitItem } from "./Benefits"

const PLAYER_REQ: PlayerTokenRequirement = {
  required_amount: 1,
  type: "player_requirement",
  token: {
    image_id: "Amenda",
    name: "Amenda",
    subtitle: "Season 21/22",
    type: "player"
  }
}

const YBEE_REQ: YbeeTokenRequirement = {
  type: "ybee_requirement",
  required_amount: 1,
  required_rarity: "Common",
}

const ITEM: BoardType = {
  active: true,
  description: "All player cards for season 2022/23 + one legendary YBEE",
  board_id: 9999,
  date_from: "Jun 1st",
  date_to: "Aug 30th",
  name: "Collectors Club",
  num_requirements: 5,
  countdown_sec: 30000,
  num_requirements_fulfilled: 3,
  fulfilled: false,
  fulfillment_final: false,
  requirements: [],
  benefits: []
}

const ALL_BENEFITS = _.map(BENEFIT_TYPES, (bt, index) => {
  return {
    type: bt,
    name: [
      "Einladung in die Valiant Lounge für ein Spiel (2 Personen)",
      "Zwei Rückrundenkarten 22/23",
      "Physische Autogrammkarten aller Gesammelten Spieler",
    ][index % 3]
  }
})

const YBEE_TOKEN: YbeeToken = {
  image_id: "1",
  rarity: "Common",
  type: "ybee"
}

const Sandbox:React.FC = () => {
  return <>
    <PageTitle>Boards List</PageTitle>
    <PageContent>
      <SectionTitle>Fulfillment Info</SectionTitle>
      <Note>Fulfilled, not final</Note>
      <Block>
        <FulfillmentInfo board={{...ITEM, fulfilled: true, fulfillment_final: false}} />
      </Block>
      <Note>Fulfilled, final</Note>
      <Block>
        <FulfillmentInfo board={{...ITEM, fulfilled: true, fulfillment_final: true}} />
      </Block>
      <Note>Not Fulfilled, not final (show nothing)</Note>
      <Block>
        <FulfillmentInfo board={{...ITEM, fulfilled: false, fulfillment_final: false}} />
      </Block>
      <Note>Not Fulfilled, final</Note>
      <Block>
        <FulfillmentInfo board={{...ITEM, fulfilled: false, fulfillment_final: true}} />
      </Block>
      <Note>no "fulfilled" info (means user not logged in)</Note>
      <Block>
        <FulfillmentInfo board={{...ITEM, fulfilled: undefined, fulfillment_final: true}} />
      </Block>
      <SectionTitle>Benefits</SectionTitle>
      <Block slim>
        <Benefits benefits={ALL_BENEFITS} />
      </Block>
      <SectionTitle>Normal board, some req</SectionTitle>
      <Block framed>
        <ContentRow>
          <Board board={{...ITEM,
            benefits: ALL_BENEFITS,
            requirements: [
              {...PLAYER_REQ},
              {...PLAYER_REQ, collected_amount: 1, fulfilled: true},
              {...PLAYER_REQ, required_amount: 2, collected_amount: 1},
              {...PLAYER_REQ},
              {...YBEE_REQ, fulfilled: false, required_samples: [
                {...YBEE_TOKEN, image_id: "1"},
                {...YBEE_TOKEN, image_id: "2"},
                {...YBEE_TOKEN, image_id: "3"}
              ]},
              {...YBEE_REQ, required_rarity: "Epic", fulfilled: true, collected_amount: 99, collected_samples: [
                {...YBEE_TOKEN, image_id: "1"},
                {...YBEE_TOKEN, image_id: "2"},
                {...YBEE_TOKEN, image_id: "3"}
              ]},
              {...YBEE_REQ, fulfilled: true, collected_amount: 2, collected_samples: [
                {...YBEE_TOKEN, image_id: "2"},
                {...YBEE_TOKEN, image_id: "3"}
              ]},
              {...YBEE_REQ, fulfilled: true, collected_amount: 1, collected_samples: [
                {...YBEE_TOKEN, image_id: "3"}
              ]},
            ]
          }}/>
        </ContentRow>
      </Block>
      <SectionTitle>Countdown already over</SectionTitle>
      <Block framed>
        <ContentRow>
          <Board board={{...ITEM, countdown_sec: 0}}/>
        </ContentRow>
      </Block>
      <SectionTitle>Currently fulfilled but not yet "rated" (should show warning about timing)</SectionTitle>
      <Block framed>
        <ContentRow>
          <Board board={{...ITEM, fulfilled: true, fulfillment_final: false}}/>
        </ContentRow>
      </Block>
      <SectionTitle>fulfilled and "rated"</SectionTitle>
      <Block framed>
        <ContentRow>
          <Board board={{...ITEM, fulfilled: true, fulfillment_final: true}}/>
        </ContentRow>
      </Block>
    </PageContent>
  </>
}

export default Sandbox
