export const initialPopupCompState = {
  nextPopupKey: 0,
  popups: ([] as (IPopup & {popupKey: number})[])
}

export interface IPopup<T = any> {
  popupId: string,
  popupPayload?: T
  showCloseIcon?: boolean
  closeOnClickOutside?: boolean
}

export type PopupCompState = typeof initialPopupCompState
