import React, { useState } from "react"

import { PageTitle, SectionTitle, Block, Note } from '@root/Sandbox'
import { LoginWarning } from "."

const Sandbox:React.FC = () => {
  return <>
    <PageTitle>Boards Page</PageTitle>

    <SectionTitle>Login warning</SectionTitle>
    <Note>Shown on top if viewing boards page but not logged in</Note>
    <Block slim>
      <LoginWarning login={() => console.log("login")} />
    </Block>
  </>
}

export default Sandbox
