import { config } from "@onflow/fcl"
import { State } from "@root/state"

const WALLET_DISCOVERY_METHOD = "POP/RPC"
const APP_NAME = "Test App"

const useFCLConfig = (generic: State["generic"]) => {
  config({
    "discovery.wallet": generic.wallet_discovery,
    "discovery.wallet.method": WALLET_DISCOVERY_METHOD,
    "accessNode.api": generic.flow_client_url,
    "app.detail.title": APP_NAME,
  })
}

export default useFCLConfig