import { useState, useEffect } from 'react'

const SEC_PER_M = 60
const SEC_PER_H = SEC_PER_M * 60
const SEC_PER_D = SEC_PER_H * 24

const minTwoDigits = (num: number) => {
  return num < 10 ? `0${num}` : `${num}`
}

const unwrapSec = (sec: number) => {
  let secLeft = sec
  const d = Math.floor(secLeft / SEC_PER_D)
  secLeft -= d * SEC_PER_D
  const h = Math.floor(secLeft / SEC_PER_H)
  secLeft -= h * SEC_PER_H
  const m = Math.floor(secLeft / SEC_PER_M)
  secLeft -= m * SEC_PER_M
  return {
    d: `${d}`,
    hh: minTwoDigits(h),
    mm: minTwoDigits(m),
    ss: minTwoDigits(secLeft)
  }
}

const useCountdown = ({secondsLeft: initialSecondsLeft, onOver}: {secondsLeft: number, onOver?(): void}) => {
  const [secondsLeft, setSecondsLeft] = useState(initialSecondsLeft)
  const [mountTime] = useState(new Date())
  const [_updatePlease, __setUpdatePlease] = useState(0)
  const [countdownRunning, setCountdownRunning] = useState(secondsLeft > 0)

  useEffect(() => {
    if (!countdownRunning) { return } // only start countdown when necessary

    let intervalId = window.setInterval(() => {
      const newSecondsLeft = Math.floor(Math.max(0, secondsLeft - ((new Date().getTime() - mountTime.getTime()) / 1000)))

      if (newSecondsLeft <= 0) {
        setCountdownRunning(false)
        onOver?.()
      }
      setSecondsLeft(newSecondsLeft)
    }, 500)

    return () => { // cleanup
      intervalId && clearInterval(intervalId)
    }
  }, [countdownRunning])

  return {
    totalSecondsLeft: secondsLeft,
    ...unwrapSec(secondsLeft)
  }
}

export default useCountdown
