import fillWithBabelMarkdown from "@root/helper/fillWithBabelMarkdown"
import { boardsPath, helpPath } from "@root/routes"
import { NeoFC } from "@root/types"
import { ContentRow, PageContent, PageTitle } from "@root/ui/Layout"
import I18n from "i18n-js"
import React from "react"
import helpSubnav from "./helpSubnav"

import "./HowItWorks.scss"

const HowItWorks: NeoFC = () => {
  return <>
    <PageTitle subnav={helpSubnav({active: "how_it_works"})}>{I18n.t("navi.help_and_faq")}</PageTitle>
    <PageContent variant="wider">
      <ContentRow>
        <div className="neo__howItWorks__text" {...fillWithBabelMarkdown("how_it_works.page_content", {faq_path: helpPath, boards_url: boardsPath() })} />
      </ContentRow>
    </PageContent>    
  </>
}

export default HowItWorks
