import isMobile from "./isMobile"

export const isMetaMaskMobileApp = () => {
  return isMobile() && isMetaMask()
}

export const isMetaMask = () => {
  return !!window.ethereum?.isMetaMask
}

export default isMetaMask
