import { Action, ActionForType, ActionType } from "@root/actions"
import { ApiSagaContext } from "@root/api"
import { all, put, takeEvery, getContext } from "redux-saga/effects"

const reload = function*(action: ActionForType<"LANDING_RELOAD_ASYNC">) {
  const api: ApiSagaContext = yield getContext("api")
  const { data } = yield api.post("/landing/reload")
  yield put<Action>({ type: "LANDING_INIT", data})
}

export function* landingSaga() {
  yield all([
    takeEvery<ActionType>("LANDING_RELOAD_ASYNC", reload)
  ])
}
