import useCountdown from "@root/helper/useCountdown"
import I18n from "i18n-js"
import _ from "lodash"
import React, { useState, useEffect } from "react"

import "./Countdown.scss"

interface CountdownProps {secondsLeft: number, onDone?(): void}
const __Countdown: React.FC<CountdownProps> = ({secondsLeft, onDone}) => {
  const [countdownOver, setCountdownOver] = useState(false)
  const {d, hh, mm, ss, totalSecondsLeft} = useCountdown({secondsLeft, onOver: () => setCountdownOver(true)})

  useEffect(() => { // when countdown over: callback *once* after a few sec (to prevent annoying loops)
    let timeout: number
    if (countdownOver) {
      timeout = window.setTimeout(() => {
        onDone?.()
      }, 2000)
    }

    return () => { //cleanup
      _.isNumber(timeout) && window.clearTimeout(timeout)
    }
  }, [countdownOver])

  return <div className="neo__countdown">
    <div className="neo__countdown__section">
      <strong>{d}</strong>
      <span>{I18n.t("countdown.label_day")}</span>
    </div>
    <div className="neo__countdown__section">
      <strong>{hh}</strong>
      <span>{I18n.t("countdown.label_hour")}</span>
    </div>
    <div className="neo__countdown__section">
      <strong>{mm}</strong>
      <span>{I18n.t("countdown.label_min")}</span>
    </div>
  </div>
}
export const Countdown: React.FC<CountdownProps> = (props) => {
  // remount when secondsLeft changes (assumption: value was updated, use new "now")
  return <__Countdown key={props.secondsLeft} {...props} />
}

export default Countdown
