import { Dispatch } from "@reduxjs/toolkit";
import { Action } from "@root/actions";
import { State } from "@root/state";
import { useEffect } from "react"
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export default () => {
  const authToken = useSelector<State, string>((st) => st.generic.user?.auth_token)
  const dispatch = useDispatch<Dispatch<Action>>()

  useEffect(() => { // make sure session-wallet is same as connected one!
    if (authToken) {
      dispatch({ type: "ACCOUNT_MAKE_SURE_CONNECTED_USER_IS_SAME_AS_SERVER_THINKS", data: { authToken }})
    }
  }, [authToken])
}
