import { helpPath, howItWorksPath } from "@root/routes"
import { SubnavItem } from "@root/ui/Layout"
import I18n from "i18n-js"

const helpSubnav = ({active}: {active: "how_it_works" | "faq"}): SubnavItem[] => {
  return [
    {
      label: I18n.t("help_subnav.how_it_works"),
      active: active === "how_it_works",
      href: howItWorksPath
    },
    {
      label: I18n.t("help_subnav.faq_list"),
      active: active === "faq",
      href: helpPath
    }
  ]
}

export default helpSubnav
