import React, { useState } from "react"

import { PageTitle, SectionTitle, Block, Note, BlockTitle, Grid } from '@root/Sandbox'
import { ContentRow, PageContent } from "@root/ui/Layout"
import Board, { Requirement } from "."
import { Board as BoardType, PlayerTokenRequirement, YbeeToken, YbeeTokenRequirement } from "@root/types"
import _ from "lodash"

const PLAYER_REQ: PlayerTokenRequirement = {
  required_amount: 1,
  type: "player_requirement",
  token: {
    image_id: "sandbox/card",
    name: "Amenda",
    subtitle: "Season 21/22",
    type: "player"
  }
}

const PLAYER_SQUARE_REQ: PlayerTokenRequirement = {
  required_amount: 1,
  type: "player_requirement",
  token: {
    image_id: "sandbox/square",
    layout: "square",
    name: "Amenda",
    subtitle: "Season 21/22",
    type: "player"
  }
}

const YBEE_REQ: YbeeTokenRequirement = {
  type: "ybee_requirement",
  required_amount: 1,
  required_rarity: "Common",
}

const YBEE_TOKEN: YbeeToken = {
  image_id: "1",
  rarity: "Common",
  type: "ybee"
}

const ybeeSamples = (amount: number): YbeeToken[] => {
  return _.times(amount, (n) => {
    return {
      ...YBEE_TOKEN,
      image_id: `${n + 1}`
    }
  })
}

const Sandbox:React.FC = () => {
  return <>
    <PageTitle>Requirements</PageTitle>
    <PageContent>
      <SectionTitle>Player</SectionTitle>
      <Grid columnsDesktop={3} columnsPhone={2} items={[
        <>
          <Note>undef collected, 1x</Note>
          <Requirement req={{...PLAYER_REQ, required_amount: 1, store_link: {href: "https://neopoly.com"}}} />
        </>,
        <>
          <Note>1 collected, 1x, fulfilled, long name</Note>
          <Requirement req={{...PLAYER_SQUARE_REQ, token: {...PLAYER_SQUARE_REQ.token, name: "Bürgy Nicolas Sandro"}, required_amount: 1, collected_amount: 1, fulfilled: true}} />
        </>,
        <>
          <Note>1 collected, 1x, fulfilled, long name</Note>
          <Requirement req={{...PLAYER_REQ, token: {...PLAYER_REQ.token, name: "Bürgy Nicolas Sandro"}, required_amount: 1, collected_amount: 1, fulfilled: true}} />
        </>,
        <>
          <Note>1 collected, 1x, fulfilled, long name</Note>
          <Requirement req={{...PLAYER_SQUARE_REQ, token: {...PLAYER_SQUARE_REQ.token, image_id: "sandbox/meisterNFT", name: "Bürgy Nicolas Sandro"}, required_amount: 1, collected_amount: 1, fulfilled: true}} />
        </>,
        <>
          <Note>1 collected, 2x, !fulfilled</Note>
          <Requirement req={{...PLAYER_REQ, required_amount: 2, collected_amount: 1, fulfilled: false}} />
        </>,
        <>
          <Note>1 collected, 2x, !fulfilled</Note>
          <Requirement req={{...PLAYER_SQUARE_REQ, required_amount: 2, collected_amount: 1, fulfilled: false}} />
        </>,
      ]} />
      <SectionTitle>Ybee</SectionTitle>
      <Grid columnsDesktop={3} columnsPhone={2} items={[
        <>
          <Note>undef collected, 1x, common, 3 samples</Note>
          <Requirement req={{...YBEE_REQ, required_amount: 1, required_samples: ybeeSamples(3)}} />
        </>,
        <>
          <Note>undef collected, 1x, common, 4 samples</Note>
          <Requirement req={{...YBEE_REQ, required_amount: 1, required_samples: ybeeSamples(5)}} />
        </>,
        <>
          <Note>undef collected, 1x, common, 5 samples</Note>
          <Requirement req={{...YBEE_REQ, required_amount: 1, required_samples: ybeeSamples(5)}} />
        </>,
        <>
          <Note>2 collected, 99x, !fulfilled</Note>
          <Requirement req={{...YBEE_REQ, required_amount: 99, collected_amount: 2, fulfilled: false, collected_samples: ybeeSamples(2)}} />
        </>,
        <>
          <Note>1 collected, 1x, fulfilled, epic</Note>
          <Requirement req={{...YBEE_REQ, required_rarity: "Epic", required_amount: 1, collected_amount: 1, fulfilled: true, collected_samples: ybeeSamples(1)}} />
        </>,
        <>
          <Note>no required rarity</Note>
          <Requirement req={{...YBEE_REQ, required_rarity: undefined, required_amount: 1, collected_amount: 1, fulfilled: true, collected_samples: ybeeSamples(1)}} />
        </>,
      ]} />
    </PageContent>
  </>
}

export default Sandbox
