import React, { useState } from "react"

import { PageTitle, SectionTitle, Block, Note, BlockTitle } from '@root/Sandbox'
import { ContentRow, PageContent } from "@root/ui/Layout"
import { NeoFC } from "@root/types"
import ProgressBar from "."

const Positioner: NeoFC = ({children}) => {
  return <div style={{position: "relative", display: "inline-flex", width: "240px", height: "60px", justifyContent: "stretch", alignItems: "stretch" }}>{children}</div>
}

const Sandbox:React.FC = () => {
  const [amount, setAmount] = useState(0)

  return <>
    <PageTitle>ProgressBar</PageTitle>
    <PageContent>
      <Note>
        Change:{" "}
        <a onClick={() => setAmount(0)}>0</a>{" "}
        <a onClick={() => setAmount(1)}>1</a>{" "}
        <a onClick={() => setAmount(2)}>2</a>{" "}
        <a onClick={() => setAmount(3)}>3</a>{" "}
        <a onClick={() => setAmount(4)}>4</a>{" "}
        <a onClick={() => setAmount(5)}>5</a>{" "}
      </Note>
      <ContentRow>
        <Positioner><ProgressBar amount={amount} total={5} /></Positioner>
      </ContentRow>
      <Note>-/5</Note>
      <ContentRow>
        <Positioner><ProgressBar total={5} /></Positioner>
      </ContentRow>
      <Note>0/5</Note>
      <ContentRow>
        <Positioner><ProgressBar amount={0} total={5} /></Positioner>
      </ContentRow>
      <Note>3/5</Note>
      <ContentRow>
        <Positioner><ProgressBar amount={3} total={5} /></Positioner>
      </ContentRow>
      <Note>5/5</Note>
      <ContentRow>
        <Positioner><ProgressBar amount={5} total={5} /></Positioner>
      </ContentRow>
    </PageContent>
  </>
}

export default Sandbox
