import React, { useCallback, useRef, useState } from "react"

import { PageTitle, SectionTitle, Block, Note } from '@root/Sandbox'
import { ContentRow } from "@root/ui/Layout"
import { IPopup } from "./state"
import { Popup, PopupAction, PopupCenteredMessage, PopupInnerFC, RawWithPopup, registerPopup, simpleErrorPopup, connectWalletPopup } from "."
import _ from "lodash"
import { OnResultFunction } from "react-qr-reader"

const TestPopup1: PopupInnerFC<{testVal: string, openOtherPopup}> = ({closePopup, testVal, openOtherPopup}) => {
  return <Popup title={`Passed value: ${testVal}`}>
    <ul>
      <li><a onClick={() => closePopup()}>close, please</a></li>
      <li><a onClick={() => closePopup({result: "hello, this is a passed result"})}>close, please with result</a></li>
    </ul>
    <PopupAction onClick={openOtherPopup}>Open another popup</PopupAction>
  </Popup>
}
const TestPopup2: PopupInnerFC<{testVal: string}> = ({closePopup, testVal}) => {
  return <Popup variant="error" title="Another Popup">
    <PopupAction onClick={() => closePopup()}>close, please</PopupAction>
  </Popup>
}
const TestPopup3: PopupInnerFC = ({closePopup}) => {
  return <Popup title="Long popup">
    <ul>
      {_.map(_.times(100), (val, index) => {
        return <li key={index}>{val}</li>
      })}
    </ul>
    <PopupAction onClick={() => closePopup()}>Close me</PopupAction>
  </Popup>
}
const TestPopup4: PopupInnerFC = ({closePopup}) => {
  return <Popup variant="error" title="Uff!">
    <PopupCenteredMessage>An error occured!</PopupCenteredMessage>
    <PopupAction onClick={() => closePopup()}>OK</PopupAction>
  </Popup>
}
registerPopup("__test__1", TestPopup1)
registerPopup("__test__2", TestPopup2)
registerPopup("__test__3", TestPopup3)
registerPopup("__test__4", TestPopup4)

const Sandbox:React.FC = () => {
  const popupKeyRef = useRef(0)
  const [currentPopup, __setCurrentPopup] = useState<IPopup>()
  const [lastCloseResult, setLastCloseResult] = useState<any>()

  const setCurrentPopup: (popup: IPopup) => void = (popup) => {
    popupKeyRef.current = popupKeyRef.current + 1
    __setCurrentPopup(popup)
  }

  const closePopup = (popupId: string, resp?: any) => {
    __setCurrentPopup(undefined)
    setLastCloseResult([popupId, resp])
  }

  // const onResult: OnResultFunction = useCallback((res, err) => {
  //   if (err?.message) {
  //     setCurrentPopup(simpleErrorPopup(err.message, "OK"))
  //   } else {
  //     if (res) {
  //       alert(res.getText())
  //       closePopup(QR_SCANNER_POPUP_ID)
  //     }
  //   }
  // }, [setCurrentPopup, closePopup])

  const popup1Conf: IPopup = {
    popupId: "__test__1",
    popupPayload: {
      testVal: "this is a passed testval",
      openOtherPopup: () => {
        setCurrentPopup({popupId: "__test__2", popupPayload: {testVal: "test val for popup2"}, showCloseIcon: true})
      }
    }
  }

  return <RawWithPopup popupKey={popupKeyRef.current} popup={currentPopup} closePopup={closePopup}>
    <PageTitle>Popup</PageTitle>

    <SectionTitle></SectionTitle>
    <Block>
      <ContentRow>
        <ul>
          <li><a onClick={() => setCurrentPopup({...popup1Conf})}>Show popup</a></li>
          <li><a onClick={() => setCurrentPopup({...popup1Conf, showCloseIcon: true})}>Show popup /w close icon</a></li>
          <li><a onClick={() => setCurrentPopup({...popup1Conf, showCloseIcon: true, closeOnClickOutside: true})}>Show popup /w close icon and close on click outside</a></li>
          <li><a onClick={() => setCurrentPopup({popupId: "__test__4", closeOnClickOutside: true, showCloseIcon: true})}>Popup with variant "error"</a></li>
          <li><a onClick={() => setCurrentPopup({popupId: "__test__3", closeOnClickOutside: true, showCloseIcon: true})}>Show long popup to test mobile scrolling</a></li>
          <li><a onClick={() => setCurrentPopup({...simpleErrorPopup("this is an error", "ok")})}>simpleErrorPopup</a></li>
          <li><a onClick={() => setCurrentPopup({...simpleErrorPopup(`underlying network changed (event="changed", network={"name":"maticmum","chainId":80001,"ensAddress":null,"_defaultProvider":null}, detectedNetwork={"name":"rinkeby","chainId":4,"ensAddress":"0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"}, code=NETWORK_ERROR, version=providers/5.6.8)`, "ok")})}>long simpleErrorPopup</a></li>
          <li><a onClick={() => setCurrentPopup({...connectWalletPopup()})}>connectWalletPopup</a></li>
        </ul>
      </ContentRow>
      <ContentRow>
        Last close result: {JSON.stringify(lastCloseResult)}
      </ContentRow>
    </Block>
  </RawWithPopup>
}

export default Sandbox
