import { LightTitleText } from "@root/ui/Layout"
import I18n from "i18n-js"
import _ from "lodash"
import React from "react"
import Countdown from "../Countdown"
import ProgressBar from "../ProgressBar"

import "./Progress.scss"

const Progress: React.FC<{countdownSec?: number, progressFromOf?: number[], onCountdownOver?(): void}> = ({countdownSec, progressFromOf, onCountdownOver}) => {
  return <div className="neo__board__progress">
    <div className="neo__board__progress__section">
      <h3>{I18n.t("board_countdown.title")}</h3>
      <Countdown secondsLeft={countdownSec || 0} onDone={onCountdownOver} />
    </div>
    {progressFromOf && <div className="neo__board__progress__section">
        <h3>{I18n.t("board_progress.title")}{" "}<LightTitleText>{progressFromOf[0]}/{progressFromOf[1]}</LightTitleText></h3>
        <div className="neo__board__progress__progressBarHolder">
          <ProgressBar amount={progressFromOf[0]} total={progressFromOf[1]} />
        </div>
      </div>}
  </div>
}

export default Progress
