import fillWithBabelMarkdown from "@root/helper/fillWithBabelMarkdown"
import I18n from "i18n-js"
import React, { ReactNode } from "react"
import { ContentRow, PageContent, SectionTitle, PageTitle } from "@root/ui/Layout"

import "./FAQ.scss"
import _ from "lodash"
import { useSelector } from "react-redux"
import { State } from "@root/state"
import helpSubnav from "./helpSubnav"
import WithAppearEffect from "@root/ui/WithAppearEffect"
import { useDispatch } from "react-redux"
import { Action } from "@root/actions"
import { Dispatch } from "@reduxjs/toolkit"

import storeImg from "@images/icon__paniniStore@3x.png"

const QUESTION_IDS = [
  "what_is_bscboards",
  "how_does_the_reward_system_work",
  "what_is_the_collectors_club",
  "where_to_buy",
  "how_to_collect",
  "display_after_transaction",
  "what_is_dapper",
  "how_to_create_dapper_wallet",
  "need_help",
] as const

type QuestionId = typeof QUESTION_IDS[number]

const ADDITIONAL_CONTENT_FOR_ANSWER: Partial<Record<QuestionId, React.FC>> = {
}

interface FAQProps {
  // todo: what do we need here?
}

const FAQEntry: React.FC<{faqId: QuestionId} & FAQProps> = (props) => {
  const AdditionalContent = ADDITIONAL_CONTENT_FOR_ANSWER[props.faqId]
  return <WithAppearEffect effect="slideUp" mode="onlyIfInitiallyHidden">
    <div className="neo__faq__entry">
      <h4>{I18n.t(`faq.${props.faqId}.q`, props)}</h4>
      <div>
        <div {...fillWithBabelMarkdown(`faq.${props.faqId}.a`, props)}/>
        {AdditionalContent && <AdditionalContent />}
      </div>      
    </div>
  </WithAppearEffect>
}

const FAQRaw: React.FC<FAQProps> = (paramsForBabel) => {
  return <div className="neo__faq__list">
    {_.map(QUESTION_IDS, (q) => {
      return <ContentRow key={q}>
        <FAQEntry faqId={q} {...paramsForBabel} />
      </ContentRow>
    })}
  </div>
}

const FAQ: React.FC = () => {
  const paramsForBabel = useSelector((st: State) => {
    return {
    }
  })
  return <>
    <PageTitle subnav={helpSubnav({active: "faq"})}>{I18n.t("navi.help_and_faq")}</PageTitle>
    <PageContent variant="wider">
      <ContentRow>
        <p className="neo__faq__desc">
          {I18n.t("faq.description")}
        </p>
      </ContentRow>
      <FAQRaw {...paramsForBabel} />
    </PageContent>
  </>
}

export default FAQ
