import React from "react"

import { NeoFC } from "@root/types"
import { useSelector } from "react-redux"
import { State } from "@root/state"
import I18n from "i18n-js"
import _ from "lodash"
import "./Header.scss"
import AccountSection from "@components/AccountSection"
import { boardsPath, helpPath, rootPath } from "@root/routes"
import headerTitleSrc from "@images/logo__yb.png"

type ActiveNav = "home" | "boards" | "helpAndFAQ"
interface NavItem {
  type?: ActiveNav
  title(): string
  url: string
  sub?: NavItem[]
  hideWhenComingSoon?: Boolean
}

const NAVI_ITEMS: NavItem[] = [
  {
    type: "home",
    title() { return I18n.t("navi.start") },
    url: rootPath
  },
  {
    type: "boards",
    title() { return I18n.t("navi.boards") },
    url: boardsPath(),
    hideWhenComingSoon: true
  },
  {
    type: "helpAndFAQ",
    title() { return I18n.t("navi.help_and_faq") },
    url: helpPath
  },
]

export const HeaderRaw: NeoFC<{activeNav?: ActiveNav, hideNav?: boolean, comingSoon?: Boolean}> = ({ activeNav, children: AccountSection, hideNav, comingSoon }) => {
  return <div className="neo__header">
    <div className="neo__header__topSection">
      <div className="neo__header__topSection__content">
        <div className="neo__header__title">
          <a href={rootPath}>            
            <img src={headerTitleSrc} />
            <h1>{I18n.t("header.title")}</h1>
          </a>
        </div>
        <div className="neo__header__accountSection">
          {AccountSection}
        </div>
      </div>
    </div>
    {!hideNav &&
      <div className="neo__header__bottomSection">
        <div className="neo__header__bottomSection__inner">
          <div className="neo__navi">
            <ul>
              {_.map(_.filter(NAVI_ITEMS, (item) => { return !(comingSoon && item.hideWhenComingSoon)}), (item, index) => {
                const className = [
                  "neo__navi__item",
                  activeNav === item.type ? "neo__navi__item--active" : "neo__navi__item--notActive"
                ].join(" ")
                return <li key={index} className={className}><a href={item.url}><span>{item.title()}</span></a></li>
              })}
            </ul>
          </div>
        </div>
      </div>}
  </div>
}

const Header: NeoFC = () => {
  const state = useSelector<State, State>((st) => st)
  const activeNav: ActiveNav =
    (state.landing && "home")
    || (state.boards && "boards")
    || (state.info_page?.page === "help" && "helpAndFAQ")
    || (state.info_page?.page === "how_it_works" && "helpAndFAQ")
    || undefined
  return <HeaderRaw activeNav={activeNav} comingSoon={state.coming_soon}>
    <AccountSection />
  </HeaderRaw>
}

export default Header
