import React from "react"

import { PageTitle, SectionTitle, Block, Note } from '@root/Sandbox'
import isMetaMask, { isMetaMaskMobileApp } from "./isMetaMask"
import isMobile from "./isMobile"

const iconize = (val?: boolean) => val ? "✔" : "✖"

const Sandbox:React.FC = () => {
  return <>
    <PageTitle>Checks for device</PageTitle>
    <Note>
      <ul>
        <li><code>isMetaMask</code> {iconize(isMetaMask())}</li>
        <li><code>isMobile</code> {iconize(isMobile())}</li>
        <li><code>isMetaMaskMobileApp</code> {iconize(isMetaMaskMobileApp())}</li>
      </ul>
    </Note>
  </>
}

export default Sandbox
