import fillWithBabelMarkdown from "@root/helper/fillWithBabelMarkdown"
import { Board } from "@root/types"
import { ContentRow } from "@root/ui/Layout"
import I18n from "i18n-js"
import _ from "lodash"
import React from "react"
import InfoBox from "../InfoBox"

const FulfillmentInfo: React.FC<{board: Board}> = ({board}) => {
  return <>
    {board.fulfilled && !board.fulfillment_final &&
      <ContentRow>
        <InfoBox icon="fulfillmentWarning">
          <p {...fillWithBabelMarkdown("board.fulfillment_check_warning--md", {date_until: board.date_to})} />
        </InfoBox>
      </ContentRow>}
    {board.fulfillment_final && board.fulfilled &&
      <ContentRow>
        <InfoBox icon="fulfilled">
          <p>{I18n.t("board.fulfilled")}</p>
        </InfoBox>
      </ContentRow>}
    {board.fulfillment_final && _.isBoolean(board.fulfilled) && !board.fulfilled &&
      <ContentRow>
        <InfoBox icon="notFulfilled">
          <p>{I18n.t("board.not_fulfilled")}</p>
        </InfoBox>
      </ContentRow>}
  </>
}

export default FulfillmentInfo
