import { BoardRequirement, NeoFC, PlayerTokenLayoutType, YbeeToken } from "@root/types"
import React, { ReactNode } from "react"
import _ from "lodash"

import "./Requirements.scss"
import PlayerRequirement from "./PlayerRequirement"
import YbeeRequirement from "./YbeeRequirement"
import I18n from "i18n-js"

const Requirements: NeoFC = ({children}) => {
  return <div className="neo__board__requirements">
    {children}
  </div>
}

export const StoreIcon: React.FC<{href: string}> = ({href}) => {
  return <a href={href} className="neo__iconStore" target="_blank" />
}

export const ReqFoot: React.FC<{title?: ReactNode, subtitle?: ReactNode, iconArea?: ReactNode}> = ({title, subtitle, iconArea}) => {
  return <div className="neo__req__foot">
    <div className="neo__req__foot__textArea">
      <span>
        {title && <h2>{title}</h2>}
        {subtitle && <h3>{subtitle}</h3>}
      </span>
    </div>
    {iconArea && <div className="neo__req__foot__iconArea">
      {iconArea}
    </div>}
  </div>
}

export const ReqHead: React.FC<{req: BoardRequirement}> = ({req}) => {
  return <div className="neo__req__head">
    <div className="neo__req__amount"><strong>{req.collected_amount || "-"}</strong><span>/{req.required_amount}</span></div>
    <div className={`neo__req__fulfillment ${req.fulfilled ? "--fulfilled" : "--notFulfilled"}`}><span>{req.fulfilled ? I18n.t("requirement.state_fulfilled") : I18n.t("requirement.state_not_fulfilled")}</span></div>
  </div>
}

export const ReqImg: NeoFC<{src: string, greyout?: boolean, imgLayout?: PlayerTokenLayoutType}> = ({src, children, greyout, imgLayout}) => {
  const className = [
    "neo__board__imgHolder",
    `neo__board__imgHolder--layout_${imgLayout || "card"}`,
    greyout ? "neo__board__imgHolder--greyout" : "",
  ].join(" ")

  return <div className={className}>
    <div className="neo__board__imgWrapper">
      <img className="neo__board__img" src={src} />
    </div>
    <div className="neo__board__img__content">{children}</div>
  </div>
}

export const ReqWrapper: NeoFC = ({children}) => {
  return <div className="neo__board__req">{children}</div>
}

export const Requirement: React.FC<{req: BoardRequirement}> = ({req}) => {
  return <>    
    {req.type === "player_requirement" && <PlayerRequirement req={req} />}
    {req.type === "ybee_requirement" && <YbeeRequirement req={req}/> }
  </>
}

export default Requirements
