import React from "react"
import { Provider } from "react-redux"
import { configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"

import mainSaga from "../sagas"
import reducer from "../reducers"
import App from "./App"
import { prefillCompStates, State } from "@root/state"
import { createSagaApiContext } from "@root/api"
import { LinkToSandbox } from "./Sandbox"
import useFCLConfig from "@root/flow/config"

let _store: ReturnType<typeof configureStore>

const getStore = (init: State) => {
  if (!_store) {
    const sagaMiddleware = createSagaMiddleware({
      context: {
        api: createSagaApiContext(init.generic.form_authenticity_token),
      }
    })
    _store = configureStore({
      reducer,
      middleware: [sagaMiddleware],
      preloadedState: prefillCompStates(init)
    })
    sagaMiddleware.run(mainSaga)
  }
  return _store
}

interface RootProps {
  state: State
  is_development: boolean
}

const Root: React.FC<RootProps> = ({state: initState, is_development}) => {
  const store = getStore(initState)

  useFCLConfig(initState.generic)
  
  return <Provider store={store}>
    <App />
    {is_development && <LinkToSandbox />}
  </Provider>
}

export default Root
