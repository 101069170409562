import React from "react"

export type NeoFC<Props = {}> = React.FC<React.PropsWithChildren<Props>>

export type BoardGroupId =
  | "season-22-23"

export const BENEFIT_TYPES = [
  "collectors_club_member",
  "vip_match_experience",
  "nft_airdrop",
  "superleague_tickets",
  "second_half_tickets",
  "scarf_nft_edition",
  "matchworn_jersey",
  "quadruple_jersey",
  "exclusive_merch_items",
  "ybee_wax_towel",
  "fan_merch_nft_items",
  "early_access_nft_drops",
  "ybee_honey",
  "physical_signed_cards",
  "valiant_lounge_invitation",
  "ybo",
  "youthbase",
] as const

export type BenefitType = typeof BENEFIT_TYPES[number]

export type BoardId = number

export interface Board {
  board_id: BoardId
  name: string
  description?: string
  active: boolean
  date_from: string // localized
  date_to: string // localized
  countdown_sec?: number // if active
  num_requirements: number
  num_requirements_fulfilled?: number
  fulfilled?: boolean
  fulfillment_final?: boolean // fulfillment is checked at a specific date
  requirements: BoardRequirement[]
  benefits: Benefit[]
}

export type BoardInfo = Omit<Board, "requirements" | "benefits" >

export interface Benefit {
  name: string
  description?: string
  type: BenefitType // resolves image
}

export interface BaseTokenRequirement {
  fulfilled?: boolean
  required_amount: number
  collected_amount?: number
  store_link?: {
    href: string
  }
}
export const YBEE_RARITIES = [
  "Uncommon",
  "Common",
  "Rare",
  "Epic",
  "Legendary",
  "Mythic"
] as const

export type YbeeRarity = typeof YBEE_RARITIES[number]

export type BoardRequirement = PlayerTokenRequirement | YbeeTokenRequirement

export type PlayerTokenRequirement =
  BaseTokenRequirement & {
    type: "player_requirement"
    token: PlayerToken
  }

export type YbeeTokenRequirement = // require 1 or more not ybees, maybe of a specific rarity
  BaseTokenRequirement & {
    type: "ybee_requirement"
    required_rarity?: YbeeRarity

    // one of
    required_samples?: YbeeToken[]
    collected_samples?: YbeeToken[]
  }

export interface PlayerToken {
  type: "player"
  name: string
  layout?: "card" | "square"
  subtitle: string
  image_id: string // "Amenda – 2", ... (?)
  // image, ...
}

export type PlayerTokenLayoutType = PlayerToken["layout"]

export interface YbeeToken {
  type: "ybee"
  rarity: YbeeRarity
  image_id: string // "1", ...
  // image, ...
}
