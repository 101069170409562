import { boardsPath } from "@root/routes"
import { BoardGroupId, BoardInfo } from "@root/types"
import React from "react"
import ProgressBar from "../ProgressBar"

import "./BoardsListItem.scss"

export const SELECTED_CLASS = "neo__boardsListItem--selected"

const BoardsListItem: React.FC<{boardGroup: BoardGroupId, info: BoardInfo, selected?: boolean, additionalClassForSelectedItem?: string}> = ({boardGroup, info, selected, additionalClassForSelectedItem}) => {
  const className = [
    "neo__boardsListItem",
    info.fulfilled ? "neo__boardsListItem--fulfilled" : "neo__boardsListItem--notFulfilled",
    selected ? SELECTED_CLASS : "neo__boardsListItem--notSelected",
    selected ? (additionalClassForSelectedItem || "") : ""
  ].join(" ")

  return <span onClick={() => window.location.href = boardsPath(boardGroup, info.board_id)} className={className}>
    <div className="neo__boardsListItem__top">
      <h3>{info.name}</h3>
      <span>{info.date_to}</span>
    </div>
    <div className="neo__boardsListItem__bottom">
      <div><span>{info.num_requirements_fulfilled || "-"}/{info.num_requirements}</span></div>
      <ProgressBar amount={info.num_requirements_fulfilled} total={info.num_requirements} /> 
    </div>
    {selected && <div className="neo__boardsListItem__selectedMarker" />}
  </span>
}

export default BoardsListItem
