import React, { useEffect, useState } from "react"

import { NeoFC } from "@root/types"

import "./Footer.scss"
import logoSrc from "@images/logo__yb.png"
import LocaleSwitcher from "./LocaleSwitcher"
import fillWithBabelMarkdown from "@root/helper/fillWithBabelMarkdown"
import { DebugMessages } from "@root/helper/debugMsg"
import I18n from "i18n-js"
import _ from "lodash"

export const socialIconsContext = require.context("@images", true, /social__/)

const SocialIcon: React.FC<{type: string}> = ({type}) => {
  return <a className={`neo__footer__socialIcon`} href={I18n.t(`social_links.${type}`)}>
    <span>{type}</span>
    <img src={socialIconsContext(`./social__${type}.png`)} />
    <img src={socialIconsContext(`./social__${type}--hover.png`)} />
  </a>
}

const ArrowButton: NeoFC<{href: string}> = ({href, children}) => {
  return <a className="neo__footer__arrowButton" href={href}><i /><span>{children}</span></a>
}

const Footer: NeoFC<{}> = ({}) => {
  const [showDebugOutWhenMod5Is0, setShowDebugOutWhenMod5Is0] = useState(1)

  const showDebugOut = showDebugOutWhenMod5Is0 % 5 === 0

  return <div className="neo__footer">    
    <div className="neo__footer__inner">
      <div className="neo__technicalRealization" {...fillWithBabelMarkdown("footer.technical_realization--md")} />
      <div className="neo__footer__top">
        <div className="neo__footer__logoSection">
          <img src={logoSrc} />
          <span>{I18n.t("footer.logo_title")}</span>
        </div>
        <div className="neo__footer__social">
          {_.map(["telegram", "facebook", "instagram", "youtube", "twitter", "tiktok"], (social) => {
            return <SocialIcon key={social} type={social} />
          })}
        </div>
      </div>
      <div className="neo__footer__columns">
        <div>
          <h3>{I18n.t("footer.address-title")}</h3>
          <div {...fillWithBabelMarkdown("footer.address--md")} />
          <p>
            <a className="neo__footer__emailLink" href={`mailto:${I18n.t("footer.email")}`}><span>{I18n.t("footer.email")}</span></a>
          </p>
        </div>
        <div>
          <p>
            <ArrowButton href={I18n.t("footer.newsletter.url")}>{I18n.t("footer.newsletter.title")}</ArrowButton>
          </p>
          <p>
            <ArrowButton href={I18n.t("footer.imprint.url")}>{I18n.t("footer.imprint.title")}</ArrowButton>
          </p>
          <p>
            <ArrowButton href={I18n.t("footer.privacy.url")}>{I18n.t("footer.privacy.title")}</ArrowButton>
          </p>
        </div>
      </div>
      <LocaleSwitcher />
    </div>
    <div style={{
      position: "absolute",
      background: "white", opacity: showDebugOut ? "0.1": "0",
      width: "3em", height: "3em", right: 0, marginTop: "-3em"
    }} onClick={() => setShowDebugOutWhenMod5Is0(prev => prev + 1)} />
    {showDebugOut && <DebugMessages />}
  </div>
}

export default Footer
