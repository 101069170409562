import { Action, ActionForType, ActionType } from "@root/actions"
import { ApiResp, ApiSagaContext } from "@root/api"
import { boardsPath } from "@root/routes"
import { State } from "@root/state"
import { all, put, takeEvery, getContext, select } from "redux-saga/effects"
import { isBoardsError } from "./state"

const reload = function*(action: ActionForType<"BOARDS_RELOAD_ASYNC">) {
  const api: ApiSagaContext = yield getContext("api")
  const state: State = yield select()

  const { data }: ApiResp<State> = yield api.post<State>(
    isBoardsError(state.boards)
    ? boardsPath()
    : boardsPath(state.boards.selected_board_group_id, state.boards.selected_board?.board_id))

  yield put<Action>({ type: "BOARDS_INIT", data: data.boards})
}

export function* boardsSaga() {
  yield all([
    takeEvery<ActionType>("BOARDS_RELOAD_ASYNC", reload)
  ])
}
