import { NeoFC } from "@root/types"
import React from "react"

import "./AccountButton.scss"

const AccountButton: NeoFC<{isLogout?: boolean, onClick?(): void, href?: string}> = ({children, onClick, href, isLogout}) => {
  const className = [
    "neo__accountButton",
    isLogout ? "neo__accountButton--isLogout" : ""
  ].join(" ")
  return <a {...{className, onClick, href}}>
    <span className="neo__accountButton__icon" />
    <span className="neo__accountButton__label">{children}</span>
  </a>
}

export default AccountButton
