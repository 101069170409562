import axios, { AxiosResponse } from "axios"

export const createSagaApiContext = (accessToken: string) => {
  return {
    post<Resp> (path: string, data?: any) { return apiPost<Resp>(path, accessToken, data) },
    authToken() { return accessToken }
  }
}

export type ApiResp<T> = AxiosResponse<T>

export type ApiSagaContext = ReturnType<typeof createSagaApiContext>

export function apiPost<Resp = any> (path: string, authToken: string, data?: any) {
  return axios.post<Resp>(
    path,
    {authenticity_token: authToken, ...data},
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }
  )
}
