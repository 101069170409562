import { Action } from "@root/actions"
import { State } from "@root/state"
import _ from "lodash"

export default (state: State, action: Action) => {
  switch (action.type) {
    case "POPUP_SHOW":
      return {
        ...state,
        comp_state: {
          ...state.comp_state,
          popup: {
            ...state.comp_state.popup,
            nextPopupKey: state.comp_state.popup.nextPopupKey + 1,
            popups: [{...action.data.popup, popupKey: state.comp_state.popup.nextPopupKey}, ...state.comp_state.popup.popups]
          }
        }
      } as State
    case "POPUP_CLOSE":
      return {
        ...state,
        comp_state: {
          ...state.comp_state,
          popup: {
            ...state.comp_state.popup,
            popups: _.filter(state.comp_state.popup.popups || [], (p) => p.popupId !== action.data.popupId)
          }
        }
      }
  }
  return state
}
