import _ from "lodash"

const openViaPOST = (url: string, authToken: string, params?: any) => {
  const data = _.merge({
    authenticity_token: authToken
  }, params)
  const form = document.createElement("form")
  form.appendChild(document.createElement("div"))

  const inputMethod = document.createElement("input")
  inputMethod.setAttribute("name", "_method")
  inputMethod.setAttribute("type", "hidden")
  inputMethod.setAttribute("value", "POST")
  form.appendChild(inputMethod)

  _.each(data, (val, key) => {
    const input = document.createElement("input")
    input.setAttribute("name", key)
    input.setAttribute("type", "hidden")
    input.setAttribute("value", val)
    form.appendChild(input)
  })

  const inputSubmit = document.createElement("input")
  inputSubmit.setAttribute("type", "submit")
  form.appendChild(inputSubmit)

  form.method = "post"
  form.action = url
  form.style.display = "none"
  document.body.appendChild(form)
  inputSubmit.click()
}

export default openViaPOST
