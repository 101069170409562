import { PlayerTokenRequirement, YbeeToken, YbeeTokenRequirement } from "@root/types"
import React from "react"
import { ReqFoot, ReqHead, ReqImg, ReqWrapper } from "."
import _ from "lodash"

import ybeeBg from "@images/ybee__req__bg.png"
import I18n from "i18n-js"
import { localizeYbeeRarity } from "@root/helper/localize"

const ybeeImgContext = require.context("@images/ybee", true, /.(jpg|png)$/)
const ybeeImg = (ybeeImageId: string) => ybeeImgContext(`./${ybeeImageId}.jpg`)

const YbeeSamples: React.FC<{ybees: YbeeToken[], greyout?: boolean}> = ({ybees, greyout}) => {
  const className = [
    "neo__board__req__ybeeSamples",
    greyout ? "neo__board__req__ybeeSamples--greyout" : "",
    `neo__board__req__ybeeSamples--amount${ybees.length}`
  ].join(" ")

  return <div className="neo__board__req__ybeeSamples__holder">
    <div className={className}>
      {_.map(ybees, (y, index) => {
        return <img src={ybeeImg(y.image_id)} key={index} />
      })}
    </div>
  </div>
}

const YbeeRequirement: React.FC<{req: YbeeTokenRequirement}> = ({req}) => {
  return <ReqWrapper>
    <ReqHead req={req} />
    <ReqImg src={ybeeBg}>
      <div className="neo__req__ybee__desc">
        <h2>{I18n.t("requirement.ybee.collect_amount", {count: req.required_amount})}</h2>
        {req.required_rarity &&
          <>
            <h4>{I18n.t("requirement.ybee.rarity_title", {count: req.required_amount})}</h4>
            <h3>{localizeYbeeRarity(req.required_rarity)}</h3>
          </>}
      </div>
      <YbeeSamples ybees={req.collected_samples || req.required_samples || []} greyout={!req.fulfilled} />
    </ReqImg>
    <ReqFoot
      title={<>{I18n.t("requirement.ybee.foot_title")}</>}
      subtitle={req.required_rarity && <>"{localizeYbeeRarity(req.required_rarity)}"</>} />
  </ReqWrapper>
}

export default YbeeRequirement
