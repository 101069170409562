import { NeoFC } from "@root/types"
import React, { useCallback } from "react"

import "./App.scss"

import Footer from "@components/Footer"
import Header from "@components/Header"
import { useSelector } from "react-redux"
import { State } from "@root/state"
import Landing from "@components/page/Landing"
import InfoPage from "@components/page/InfoPage"
import { WithPopup } from "../Popup"
import { debugError } from "@root/helper/debugMsg"
import Boards from "../page/Boards"
import useMakeSureConnectedUserIsSameAsServerThinks from "@root/account/useMakeSureConnectedUserIsSameAsServerThinks"

export const AppWrapper: NeoFC = ({children}) => {
  return <div className="neo__app">{children}</div>
}
export const AppContentWrapper: NeoFC = ({children}) => {
  return <div className="neo__appContent">{children}</div>
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidCatch(error, errorInfo) {
    debugError(error, errorInfo)
  }
  render() {
    return (this.props as any).children;
  }
}

const App: NeoFC = () => {
  const state = useSelector<State, State>((st) => st)
  useMakeSureConnectedUserIsSameAsServerThinks()

  return (
    <ErrorBoundary>
      <WithPopup>
        <AppWrapper>
          <Header />
          <AppContentWrapper>
            {state.landing && <Landing />}
            {state.boards && <Boards />}
            {state.info_page && <InfoPage />}
          </AppContentWrapper>
          <Footer />
        </AppWrapper>
      </WithPopup>
    </ErrorBoundary>
  )
}

export default App
