import "normalize.css"
import "@style/base.scss"

// setup i18n
const I18n = require('i18n-js')
I18n.defaultLocale = window.__localization.locale
I18n.locale = window.__localization.locale
I18n.translations = window.__localization.translations

// pack all images
const imageContext = require.context('../images', true, /.(png|jpg|svg)$/)
imageContext.keys().forEach((key) => { imageContext(key) })

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
