import React, { useEffect, useRef, useState } from "react"
import { useIntersectionObserver } from "react-intersection-observer-hook"

interface State {
  initDone: boolean
  initiallyVisible: boolean
  wasVisible: boolean
}

const useWasInVisibleArea = () => {
  const [ref, { entry }] = useIntersectionObserver();
  const isVisible = entry && entry.isIntersecting;
  const [state, setState] = useState<State>({initDone: false, initiallyVisible: false, wasVisible: false})
  const stateRef = useRef(state)
  stateRef.current = state

  useEffect(() => {
    if (stateRef.current.initDone) {
      if (isVisible && !stateRef.current.wasVisible) {
        setState({
          ...stateRef.current,
          wasVisible: true
        })
      }
    } else {
      if (entry) {
        setState({
          initDone: true,
          initiallyVisible: isVisible,
          wasVisible: isVisible
        })
      }
    }
  }, [isVisible, !!entry]);

  return {
    ref,
    ...state
  }
}

export default useWasInVisibleArea
