import { PlayerTokenRequirement } from "@root/types"
import React from "react"
import { ReqFoot, ReqHead, ReqImg, ReqWrapper, StoreIcon } from "."

export const playerImgContext = require.context("@images/player", true, /.(jpg|png)$/)
const playerImg = (playerImageId: string) => {
  let src = ""
  try {
    src = playerImgContext(`./${playerImageId}.png`)
  } catch(e) {
    try {
      src = playerImgContext(`./${playerImageId}.jpg`)
    } catch(e) {}
  }
  return src
}

const PlayerRequirement: React.FC<{req: PlayerTokenRequirement}> = ({req}) => {
  return <ReqWrapper>
    <ReqHead req={req} />
    <ReqImg src={playerImg(req.token.image_id)} greyout={!req.fulfilled} imgLayout={req.token.layout} />
    <ReqFoot
      title={<>{req.token.name}</>}
      subtitle={<>{req.token.subtitle}</>}
      iconArea={req.store_link && <StoreIcon href={req.store_link.href} />} />      
  </ReqWrapper>
}

export default PlayerRequirement
