import { NeoFC } from "@root/types"
import React from "react"
import I18n from "i18n-js"

import "./Boards.scss"
import { BoardsState, BoardGroupResp } from "./state"
import { useSelector, useDispatch } from "react-redux"
import { State } from "@root/state"
import { Action } from "@root/actions"
import { Dispatch } from "@reduxjs/toolkit"
import { ContentRow, PageContent, PageTitle, SectionTitle } from "@root/ui/Layout"
import fillWithBabelMarkdown from "@root/helper/fillWithBabelMarkdown"
import BoardsList from "@root/components/BoardsList"
import Board from "@root/components/Board"
import InfoBox from "@root/components/InfoBox"
import AccountButton from "@root/components/AccountSection/AccountButton"

export const LoginWarning: React.FC<{login(): void}> = ({login}) => {
  return <InfoBox icon="warning">
    <p>{I18n.t("boards.login_info")}</p>
    <p>
      <AccountButton onClick={() => login()}>{I18n.t("boards.action_login")}</AccountButton>
    </p>
  </InfoBox>
}

const NoActiveBoardsDisclaimer: React.FC = () => {
  return <ContentRow>
    <div {...fillWithBabelMarkdown("no_active_boards.info--md")}></div>
  </ContentRow>
}

function hasActiveBoards(boards: BoardGroupResp): boolean {
  return boards.board_infos.findIndex(b => b.active) > -1
}

interface BoardsProps {
  boards: BoardsState
  reload(): void
  showLoginWarning: boolean
  login(): void
}
export const BoardsRaw: NeoFC<BoardsProps> = ({ boards, reload, showLoginWarning, login }) => {
  if ("error" in boards) {
    return <div>{boards.error}</div>
  }
  return <>
    <PageTitle infoText={I18n.t("boards.info")}>{I18n.t("boards.title")}</PageTitle>
    <PageContent>
      {showLoginWarning &&
        <ContentRow>
          <LoginWarning login={login} />
        </ContentRow>}

      {!hasActiveBoards(boards) &&
        <NoActiveBoardsDisclaimer />
      }
      <ContentRow><SectionTitle>{I18n.t("board_selector.title")}</SectionTitle></ContentRow>
      <ContentRow>
        <BoardsList selected={boards.selected_board?.board_id} boardGroup={boards.selected_board_group_id} boardInfos={boards.board_infos} />
      </ContentRow>
      {boards.selected_board &&
        <ContentRow>
          <Board key={boards.selected_board.board_id} board={boards.selected_board} reload={reload} />
        </ContentRow>
    }
    </PageContent>
  </>
}

const Boards: NeoFC = () => {
  const {boardsState, showLoginWarning} = useSelector((st: State) => {
    return {boardsState: st.boards, showLoginWarning: !st.generic.user}
  })

  const dispatch = useDispatch<Dispatch<Action>>()
  const reload = () => dispatch({type: "BOARDS_RELOAD_ASYNC"})
  const login = () => dispatch({type: "ACCOUNT_LOGIN"})

  return <BoardsRaw boards={boardsState} reload={reload} login={login} showLoginWarning={showLoginWarning} />
}

export default Boards
