import { Benefit, BenefitType } from "@root/types"
import I18n from "i18n-js"
import React from "react"
import _ from "lodash"

import ScrollContainer, { useScrollContainer } from 'react-indiana-drag-scroll';

import "./Benefits.scss"
import { LightTitleText } from "@root/ui/Layout";

const benefitImgContext = require.context("@images", true, /benefit--/)
const benefitImg = (benefitType: BenefitType) => {
  try {
    return benefitImgContext(`./benefit--${benefitType}.png`)
  } catch(e) {}
  return ""
}

export const BenefitItem: React.FC<{benefit: Benefit}> = ({benefit}) => {
  return <div className="neo__benefit">
    <span>{benefit.name || benefit.description}</span>
    <div style={{backgroundImage: `url(${benefitImg(benefit.type)})`}} />
  </div>
}

const Benefits: React.FC<{benefits: Benefit[]}> = ({benefits}) => {
  return <div className="neo__benefits">
    <h3>{I18n.t("board_benefits.title")}{" "}<LightTitleText>({benefits.length})</LightTitleText></h3>
    <ScrollContainer hideScrollbars={false}>
      <div className="neo__benefits__list">
        {_.map(benefits, (b, index) => {
          return <BenefitItem key={index} benefit={b} />
        })}
      </div>
    </ScrollContainer>
  </div>
}

export default Benefits
