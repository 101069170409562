import React from "react"
import { ethers } from "ethers"

export const shortWallet = (walletAddress: string) => {
  const len = (walletAddress || "").length
  return len > 10
    ? `${walletAddress.slice(0, 5)}…${walletAddress.slice(length - 4)}`
    : walletAddress || ""
}

export const ShortWallet: React.FC<{wallet: string}> = ({wallet}) => {
  return <span title={wallet} style={{whiteSpace: "nowrap"}}>{shortWallet(wallet)}</span>
}

export const convertLongAddressFromServerToShortAddressForView = (longAddr: string) => {
  return ethers.utils.defaultAbiCoder.decode(["address"], longAddr)[0]
}
