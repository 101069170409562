import { Board, BoardGroupId, BoardId, BoardInfo } from "@root/types"

export interface BoardGroupResp {
  board_group_ids: BoardGroupId[]
  selected_board_group_id: BoardGroupId
  board_infos: BoardInfo[]
  selected_board?: Board // to prefill view with first board
}

export function isBoards(resp: BoardGroupResp | BoardGroupErrorResp): resp is BoardGroupResp {
  return (!("error" in resp))
}

export function isBoardsError(resp: BoardGroupResp | BoardGroupErrorResp): resp is BoardGroupErrorResp {
  return ("error" in resp)
}

export interface BoardGroupErrorResp {
  error: string
}

export const initialBoardsCompState = {
}

export type BoardsCompState = typeof initialBoardsCompState

export type BoardsState = 
  | BoardGroupResp
  | BoardGroupErrorResp
