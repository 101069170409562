import React, { useState } from "react"

import { PageTitle, SectionTitle, Block, Note } from '@root/Sandbox'

const Sandbox:React.FC = () => {
  return <>
    <PageTitle>Landing Page</PageTitle>

    <SectionTitle>Title</SectionTitle>
    <Block>
    </Block>
  </>
}

export default Sandbox
