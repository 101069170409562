import { Board as BoardType } from "@root/types"
import _ from "lodash"
import { ContentRow, SectionTitle } from "@root/ui/Layout"
import I18n from "i18n-js"
import { marked } from "marked"
import React, { useEffect } from "react"
import "./Board.scss"
import Progress from "./Progress"
import Benefits from "./Benefits"
import FulfillmentInfo from "./FulfillmentInfo"
import Requirements, { Requirement } from "./Requirements"

const Board: React.FC<{board: BoardType, reload?(): void}> = ({board, reload}) => {
  return <>
    <ContentRow>
      <SectionTitle innerHTML={marked(I18n.t("board.title--md", {board_name: board.name}))} />
      {board.description && <p>{board.description}</p>}
    </ContentRow>
    <FulfillmentInfo board={board} />
    <ContentRow>
      <div className="neo__board__boxesHolder">
        <Progress countdownSec={board.countdown_sec} onCountdownOver={reload} progressFromOf={_.isNumber(board.num_requirements_fulfilled) ? [board.num_requirements_fulfilled, board.num_requirements] : undefined} />
        <Benefits benefits={board.benefits} />
      </div> 
    </ContentRow>
    <ContentRow>
      <SectionTitle innerHTML={marked(I18n.t("board_requirements.title--md"))} />
      <p>{I18n.t("board_requirements.info", {board_name: board.name})}</p>
    </ContentRow>
    <ContentRow>
      <Requirements>
        {_.map(board.requirements, (r, index) => {
          return <Requirement key={index} req={r} />
        })}
      </Requirements>
    </ContentRow>
  </>
}

export default Board
