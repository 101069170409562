import React from "react"

import InfoBox, { ICONS } from "./"
import { PageTitle, SectionTitle, Block, Note } from '@root/Sandbox'
import { ContentRow } from "@root/ui/Layout"
import _ from "lodash"

const Sandbox:React.FC = () => {
  return <>
    <PageTitle>Header</PageTitle>

    <SectionTitle>None selected / home / ...</SectionTitle>
    <Block>
      {_.map(ICONS, (i) => {
        return <ContentRow key={i}>
          <InfoBox icon={i}>
            <p>Some content for Icon `{i}`</p>
            <p>Another line</p>
          </InfoBox>
        </ContentRow>
      })}
    </Block>
  </>
}

export default Sandbox
