import React, { useState } from "react"

import { Grid, PageTitle as SBPageTitle, SectionTitle as SBSectionTitle, Block, Note } from '@root/Sandbox'
import { Button, ContentRow, ErrorMessage, PageTitle, SectionTitle, SectionTopSeparator } from "@root/ui/Layout"
import WithAppearEffect from "./WithAppearEffect"

const Sandbox:React.FC = () => {
  const [errorMsg, setErrorMsg] = useState("Test error message")
  return <>
    <SBPageTitle>Layout</SBPageTitle>
    <Block>
      <SBSectionTitle>PageTitle</SBSectionTitle>
    </Block>
    <Block>
      <PageTitle infoText="This is an info text.">PageTitle /w info</PageTitle>
    </Block>
    <Block>
      <PageTitle
        subnav={[
          {label: "Nav1 w/ href", active: true, target: "_blank", href: "/"},
          {label: "Nav2 w/ onClick", onClick() { console.log("nav2 click")}}
        ]} >Page Title with Subnav</PageTitle>
    </Block>
    <Block>
      <PageTitle
        subnav={[
          {label: "Nav1 w/ href", active: true, target: "_blank", href: "/"},
          {label: "Nav2 w/ onClick", onClick() { console.log("nav2 click")}}
        ]} >Page Title with Subnav</PageTitle>
    </Block>
    <Block>
      <PageTitle
        infoText="This is an info text"
        subnav={[
          {label: "Nav1 w/ href", active: true, target: "_blank", href: "/"},
          {label: "Nav2 w/ onClick", onClick() { console.log("nav2 click")}}
        ]} >Page Title with Subnav + info</PageTitle>
    </Block>
    <Block>
      <ContentRow>
        <SectionTitle>Section Title</SectionTitle>
      </ContentRow>
    </Block>
    <Block>
      <ContentRow>
        <Note>SectionTopSeparator</Note>
        <SectionTopSeparator />
      </ContentRow>
    </Block>
    <SBPageTitle>Button</SBPageTitle>
    <Block>
      <Grid columnsPhone={1} items={[
        <ContentRow>
          <Button onClick={() => console.log("buttonclick")}>Button w/ onclick</Button>
        </ContentRow>,
        <ContentRow>
          <Button href="/" target="_blank">Button w/ href</Button>
        </ContentRow>,
        <ContentRow>
          <Button emphasize href="/" target="_blank">Emphasized Button</Button>
        </ContentRow>,
        <ContentRow>
          <Button onClick={() => console.log("you should not see this")} disabled>Button disabled</Button>
        </ContentRow>,
        <ContentRow>
          <Button subtitle="Button subtitle">Button w/ string subtitle</Button>
        </ContentRow>,
        <ContentRow>
          <Button emphasize subtitle="Button subtitle">Emphasized Button w/ subtitle</Button>
        </ContentRow>,
        <ContentRow>
          <Button subtitle={<><em>ReactNode</em>{" "}<strong>subtitle</strong></>}> Button w/ ReactNode subtitle</Button>
        </ContentRow>,
        <ContentRow>
          <Button disabled subtitle={<><em>ReactNode</em>{" "}<strong>subtitle</strong></>}> Button disabled w/ subtitle</Button>
        </ContentRow>
      ]} />
    </Block>
    <SBPageTitle>ErrorMessage</SBPageTitle>
    <Note>
      <p>Error message with min 3 lines height | ...also center text vertically in minLinesHeight | only neede height</p>
      <p>
        <a onClick={() => setErrorMsg("")}>clear</a>
        {" | "}<a onClick={() => setErrorMsg("Test error message 1")}>Msg1</a>
        {" | "}<a onClick={() => setErrorMsg("Test error message 2")}>Msg2</a>
      </p>
    </Note>
    <Block>
      <ContentRow>
        <div style={{border: "1px solid #ccc"}}><ErrorMessage message={errorMsg} minLinesHeight={3} /></div>
      </ContentRow>
      <ContentRow>
        <div style={{border: "1px solid #ccc"}}><ErrorMessage message={errorMsg} minLinesHeight={3} centerTextVertically /></div>
      </ContentRow>
      <ContentRow>
        <div style={{border: "1px solid #ccc"}}><ErrorMessage message={errorMsg} /></div>
      </ContentRow>
    </Block>
    <SBPageTitle>WithAppearEffect</SBPageTitle>
    <Block>
      <Grid items={[
          <WithAppearEffect effect="slideUp" mode="onlyIfInitiallyHidden">
            <div style={{background: "#ccc", height: "80px", display: "flex", alignItems: "center"}}>
              appear when in scrolled view (slideUp), only when not initially visible
            </div>
          </WithAppearEffect>,
          <WithAppearEffect effect="slideUp" mode="always">
            <div style={{background: "#ccc", height: "80px", display: "flex", alignItems: "center"}}>
              always show appear ani, even if initialy visible
            </div>
          </WithAppearEffect>,
          <WithAppearEffect effect="slideUp" mode="always" delaySec={1}>
            <div style={{background: "#ccc", height: "80px", display: "flex", alignItems: "center"}}>
              delay 1s
            </div>
          </WithAppearEffect>,
          <WithAppearEffect effect="opacity" mode="always">
            <div style={{background: "#ccc", height: "80px", display: "flex", alignItems: "center"}}>
              opacity
            </div>
          </WithAppearEffect>,
          <WithAppearEffect effect="scaleUp" mode="always">
            <div style={{background: "#ccc", height: "80px", display: "flex", alignItems: "center"}}>
              scaleUp
            </div>
          </WithAppearEffect>
        ]} />
    </Block>
  </>
}

export default Sandbox
