import { State } from "@root/state"
import { NeoFC } from "@root/types"
import React, { CSSProperties, useEffect } from "react"
import { useSelector } from "react-redux"
import { IPopup, PopupCompState } from "./state"

import "./Popup.scss"
import { useDispatch } from "react-redux"
import { Dispatch } from "@reduxjs/toolkit"
import { Action } from "@root/actions"
import I18n from "i18n-js"

export const RawPopup: NeoFC = () => {
  return null
}

export type PopupCloseResponse<T> = T | { justClosed: true }

export type PopupInnerFCProps<Props = {}> = Props & { closePopup(resp?: any): void }
export type PopupInnerFC<Props = {}> = React.FC<PopupInnerFCProps<Props>>

const __reg: {[popupId: string]: React.FC} = {}
export const registerPopup = (popupId: string, comp: React.FC) => {
  if (__reg[popupId]) { throw new Error(`Popup "${popupId}" already registred`)}
  __reg[popupId] = comp
}

export const PopupCenteredMessage: NeoFC = ({children}) => <p className="neo__popup__centeredMessage">{children}</p>

export const PopupAction: NeoFC<{onClick?: () => void, href?: string}> = ({children, onClick, href}) => {
  return <div className="neo__popup__action__holder">
    <a className="neo__popup__action" href={href} onClick={onClick ? () => onClick() : undefined}>{children}</a>
  </div>
}

export const Popup: NeoFC<{title?: string, innerClassName?: string, className?: string, variant?: "error" | "default"}> = ({children, variant, className: passedClassname, title, innerClassName}) => {
  const className = [
    "neo__popup",
    `neo__popup--${variant || "default"}`,
    passedClassname || ""
  ].join(" ")
  return <div className={className}>
    <div className="neo__popup__inner">
      {title && <h2 className="neo__popup__title"><span>{title}</span></h2>}
      <div className={`neo__popup__inner__content ${innerClassName || ""}`}>{children}</div>
    </div>
  </div> 
}

export const RawWithPopup: NeoFC<{popup?: IPopup, popupKey?: number, closePopup(popupId: string, resp?: any)}> = ({children, popup, popupKey, closePopup: closePopupWithNeededId}) => {
  const Current = popup && __reg[popup.popupId]

  useEffect(() => {
    if((!Current) && popup?.popupId) {
      console.log(`No popup for id '${popup.popupId}' registered`)
    }
  }, [Current, popup?.popupId])

  return <div className="neo__popup__withPopup">
    {popup && Current &&
      <div className="neo__popup__holder">
        <div className="neo__popup__backdrop" onClick={() => {if (popup.closeOnClickOutside) { closePopupWithNeededId(popup.popupId, {justClosed: true})}}}/>
        {popup.showCloseIcon &&
          <div className="neo__popup__closeIcon__holder">
            <a className="neo__popup__closeIcon" onClick={() => closePopupWithNeededId(popup.popupId, {justClosed: true})} />
          </div>}
        {<Current {...popup.popupPayload} closePopup={(resp?: any) => closePopupWithNeededId(popup.popupId, resp || {justClosed: true})} />}
      </div>}
    {children}
  </div>
}

const SimpleErrorPopup: PopupInnerFC<{errorMessage: string, actionLabel: string}> = ({closePopup, errorMessage, actionLabel}) => {
  return <Popup variant="error">
    <PopupCenteredMessage>{errorMessage}</PopupCenteredMessage>
    <PopupAction onClick={() => closePopup()}>{actionLabel}</PopupAction>
  </Popup>
}

export const SIMPLE_ERROR_POPUP_ID = "simpleErrorPopup"
registerPopup(SIMPLE_ERROR_POPUP_ID, SimpleErrorPopup)
export const simpleErrorPopup = (errorMessage: string, actionLabel: string): IPopup => {
  return {
    popupId: SIMPLE_ERROR_POPUP_ID,
    popupPayload: {errorMessage, actionLabel},
    showCloseIcon: true
  }
}

const ConnectWalletPopup: PopupInnerFC = ({closePopup}) => {
  return <Popup>
    <PopupCenteredMessage>{I18n.t("account_section.connect_wallet.info")}</PopupCenteredMessage>
    <PopupAction onClick={() => closePopup({connect: true})}>{I18n.t("account_section.connect_wallet.connect_action")}</PopupAction>
  </Popup>
}

export const CONNECT_WALLET_POPUP_ID = "connectWalletPopup"
registerPopup(CONNECT_WALLET_POPUP_ID, ConnectWalletPopup)
export const connectWalletPopup = (): IPopup => {
  return {
    popupId: CONNECT_WALLET_POPUP_ID,
    showCloseIcon: true
  }
}

export const WithPopup: NeoFC = ({children}) => {
  const currentPopup = useSelector<State, PopupCompState["popups"][number] | undefined>((st) => {
    return st.comp_state.popup.popups?.[0]
  })
  const dispatch = useDispatch<Dispatch<Action>>()
  const closePopup = (popupId: string, resp?: any) => dispatch({type: "POPUP_CLOSE", data: {popupId, result: resp}})
  return <RawWithPopup popupKey={currentPopup?.popupKey} popup={currentPopup} closePopup={closePopup}>{children}</RawWithPopup>
}
