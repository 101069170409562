import { BoardGroupId, BoardInfo, BoardId } from "@root/types"
import React, { useEffect, useRef } from "react"
import _ from "lodash"
import BoardsListItem, { SELECTED_CLASS } from "./BoardsListItem"
import ScrollContainer, { useScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css'

import "./BoardsList.scss"

const uniqueSelectedClass = (() => {
  let i = 0
  return () => {
    return `${SELECTED_CLASS}--${i++}`
  }
})()

const BoardsList: React.FC<{selected?: BoardId, boardGroup: BoardGroupId, boardInfos: BoardInfo[]}> = ({selected, boardInfos, boardGroup}) => {
  const ref = useRef<HTMLElement>(null);
  const mySelectedClassToPrescroll = useRef<string>()
  if (!mySelectedClassToPrescroll.current) { mySelectedClassToPrescroll.current = uniqueSelectedClass() }

  useEffect(() => {
    if (!ref.current) { return }
    const selected = ref.current.querySelector(`.${mySelectedClassToPrescroll.current}`)
    if (!selected) { return }
    const selectedBounds = selected.getBoundingClientRect()
    if (selectedBounds.right > ref.current.clientWidth || selectedBounds.right < 100) {
      ref.current.scrollTo({
        left: ref.current.scrollLeft + selectedBounds.right - 180
      });
    }
  }, []);

  const className = [
    "neo__boardsList__holder",
    selected ? "neo__boardsList__holder--hasSelected" : "neo__boardsList__holder--doesntHaveSelected"
  ].join(" ")

  return <div className={className}>
    <ScrollContainer ref={ref as any}>
      <div className="neo__boardsList">
        {_.map(boardInfos, (b, index) => {      
          return <BoardsListItem key={index} info={b} {...{boardGroup}} selected={selected === b.board_id} additionalClassForSelectedItem={mySelectedClassToPrescroll.current} />
        })}
        <span className="neo__boardsList__endingSpacer" />
      </div>
    </ScrollContainer>
  </div>
}

export default BoardsList
