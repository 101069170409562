import { NeoFC } from "@root/types"
import React, { ReactNode } from "react"
import _ from "lodash"

import "./Layout.scss"
import { useAppearEffectClassNames } from "./WithAppearEffect"

export type SubnavItem = {
  label: string | ReactNode
  active?: boolean
  onClick?(): void
  target?: string
  href?: string
}

export const PageTitle: NeoFC<{subnav?: SubnavItem[], infoText?: string}> = ({children: Title, subnav, infoText}) => {
  const {appearEffectClassNames, ref} = useAppearEffectClassNames(
    "neo__layout__pageTitle",
    "always");

return <div ref={ref} className={`neo__layout__pageTitle ${appearEffectClassNames}`}>
  <div className="neo__layout__pageTitle__inner">
    <h1><span>{Title}</span></h1>
    {infoText && <div className="neo__layout__pageTitle__infoText">{infoText}</div>}
    {subnav &&
      <div className="neo__layout__subnav">
        {_.map(subnav, ({onClick, target, active, href, label: itemLabel}, index) => {
          const className = [
            "neo__layout__subnav__item",
            active ? "neo__layout__subnav__item--active" : "neo__layout__subnav__item--inactive"
          ].join(" ")
          return <a key={index} {...{className, onClick, target, href}}><span>{itemLabel}</span></a>
        })}
      </div>}
    </div>
  </div>
}

const NBSP = "\u00A0"

export const ErrorMessage: React.FC<{className?: string, message?: string, minLinesHeight?: number, centerTextVertically?: boolean}> = ({message, minLinesHeight, className: passedClassName, centerTextVertically}) => {
  return <div className={`neo__layout__errorMessage__holder${centerTextVertically ? " neo__layout__errorMessage__holder--centerTextVertically" : ""} ${passedClassName || ""}`}>
    {minLinesHeight &&
      <span className="neo__layout__errorMessage__minHeight">{_.map(_.times(minLinesHeight), (val, index) => {
        return <span key={index}>{NBSP}{index < minLinesHeight - 1 ? <br /> : null}</span>})}</span>}
    {message && message.length > 0 &&
      <span key={message} className="neo__layout__errorMessage">{message}</span>}
  </div>
}

export const SectionTitle: NeoFC<{innerHTML?: string}> = ({children: Title, innerHTML}) => {
  const {appearEffectClassNames, ref} = useAppearEffectClassNames(
    "neo__layout__sectionTitle",
    "always");
  return <div ref={ref} className={`neo__layout__sectionTitle ${appearEffectClassNames}`}>
    {innerHTML
      ? <h2 dangerouslySetInnerHTML={{__html: innerHTML}} />
      : <h2>{Title}</h2>}
  </div>
}

export const LightTitleText: NeoFC = ({children}) => {
  return <span className="neo__layout__lightTitleText">{children}</span>
}

export const SectionTopSeparator: NeoFC = () => <div className="neo__layout__sectionTitle" />

interface ContentRowProps {
  aBitNarrow?: boolean
  veryNarrow?: boolean
  className?: string
  dangerouslySetInnerHTML?: {__html: string}
}
export const ContentRow: NeoFC<ContentRowProps> = ({children, aBitNarrow, veryNarrow, className: passthroughClassNameForInnerWrapper, dangerouslySetInnerHTML}) => {
  const className = [
    "neo__layout__contentRow",
    aBitNarrow ? `neo__layout__contentRow--aBitNarrow` : "",
    veryNarrow ? `neo__layout__contentRow--veryNarrow` : ""    
  ].join(" ")
  return <div className={className}>
    <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} className={`neo__layout__contentRow__inner ${passthroughClassNameForInnerWrapper || ""}`}>{children}</div>
  </div>
}

export const PageContent: NeoFC<{className?: string, variant?: "wider"}> = ({children, className: passthroughClassName, variant}) => {
  const className = [
    "neo__layout__pageContent",
    variant ? `neo__layout__pageContent--${variant}` : "",
    passthroughClassName || ""
  ].join(" ")
  return <div className={className}>
    <div className="neo__layout__pageContent__inner">
      {children}
    </div>
  </div>
}

interface ButtonProps {
  href?: string
  onClick?(): void
  target?: string
  subtitle?: string | ReactNode
  disabled?: boolean
  className?: string
  thinFont?: boolean
  emphasize?: boolean
}
export const Button: NeoFC<ButtonProps> = ({emphasize, className: passthroughClassName, target, children: Title, href, onClick, subtitle, disabled, thinFont}) => {
  const className = [
    "neo__button",
    subtitle ? "neo__button--withSubtitle" : "neo__button--withoutSubtitle",
    emphasize ? "neo__button--emphasize" : "neo__button--dontEmphasize",
    thinFont ? "neo__button--thinFont" : "",
    passthroughClassName || ""
  ].join(" ")
  const Inner = <span className="neo__button__inner">
    <span className="neo__button__title">{Title}</span>
    {subtitle && <span className="neo__button__subtitle">{subtitle}</span>}
  </span>
  return disabled
    ? <span className={className}>{Inner}</span>
    : <a {... {className, onClick, target, href}}>{Inner}</a>
}
