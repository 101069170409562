import React, { useEffect, useState } from "react"

import { PageTitle, SectionTitle, Block, Note, BlockTitle, Grid } from '@root/Sandbox'
import { ContentRow, PageContent } from "@root/ui/Layout"
import { playerImgContext } from "./PlayerRequirement"
import _ from "lodash"

const PlayerImg: React.FC<{imgPath: string, onImgLoaded(img: any): void}> = ({imgPath, onImgLoaded}) => {
  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      onImgLoaded(img)
    }
    img.src = playerImgContext(imgPath)
  }, [])
  return <div style={{position: "relative"}}>
    <img style={{width: "80%", height: "auto"}} src={playerImgContext(imgPath)} />
  </div>
}

const Sandbox:React.FC = () => {
  const [resolutionToAmount, setResolutionToAmount] = useState<{[res: string]: number}>({})

  const onImgLoaded = (img) => {
    console.log(img, img.width, img.height)
    const key = `${img.width}x${img.height}`
    setResolutionToAmount((prev) => {
      return {
        ...prev,
        [key]: (prev[key] || 0) + 1
      }
    })
  }

  console.log(resolutionToAmount)

  return <>
    <PageTitle>Player Images Check</PageTitle>
    <PageContent>
      <Note>
        <p>Does everything look good?</p>
        <p>Which resolution how often? (at best there should only be one resolution)</p>
        <ul>
          {_.map(_.keys(resolutionToAmount), (k) => {
            return <li key={k}>{`${k} → ${resolutionToAmount[k]}x`}</li>
          })}
        </ul>
      </Note>
      <Grid columnsDesktop={10} columnsPhone={8} items={_.map(playerImgContext.keys(), (imgPath) => {
        return <PlayerImg imgPath={imgPath} onImgLoaded={onImgLoaded} />
      })} />
    </PageContent>
  </>
}

export default Sandbox
