import React, { useState } from "react"

import Countdown from "./"
import { PageTitle, SectionTitle, Block, Note } from '@root/Sandbox'
import { ContentRow } from "@root/ui/Layout"

const Sandbox:React.FC = () => {
  const [secLeftForACountdown, setSecLeftForACountdown] = useState(123)
  const [numCallbackCalled, setNumCallbackCalled] = useState(0)
  const doneCallback = () => {
    setNumCallbackCalled((prev) => prev + 1)
  }
  return <>
    <PageTitle>Countdown</PageTitle>

    <Block slim>
      <ContentRow>
        <Countdown secondsLeft={123456} />
      </ContentRow>
      <Note>Soon ending, callback called once, after 12 sec. <strong>Num callback called: {numCallbackCalled}</strong></Note>
      <ContentRow>
      <Countdown secondsLeft={12} onDone={doneCallback} />
      </ContentRow>
      <Note>Callback not called if already over (to make sure that that "num callback called" above remains zero for a bit)</Note>
      <ContentRow>
        <Countdown secondsLeft={0} onDone={doneCallback} />
      </ContentRow>
      <Note>
        Will be remounted if initial secondsLeft change.
        {" "}<a onClick={() => setSecLeftForACountdown(123567)}>123567sec</a>
        {" "}<a onClick={() => setSecLeftForACountdown(456789)}>456789sec</a>
        {" "}<a onClick={() => setSecLeftForACountdown(789012)}>789012sec</a>
      </Note>
      <ContentRow>
        <Countdown secondsLeft={secLeftForACountdown} onDone={doneCallback} />
      </ContentRow>
    </Block>
  </>
}

export default Sandbox
