import { State } from "@root/state"
import { NeoFC } from "@root/types"
import React from "react"
import { useSelector } from "react-redux"
import HowItWorks from "./HowItWorks"
import FAQ from "./FAQ"

const InfoPage: NeoFC = () => {
  const {
    infoPage,
    generic
  } = useSelector((st: State) => {
    return {
      infoPage: st.info_page,
      generic: st.generic
    }
  })
  
  switch(infoPage.page) {
    case "how_it_works": return <HowItWorks />
    case "help": return <FAQ />
  }
}

export default InfoPage
