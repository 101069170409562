import React, { useState } from "react"

import { PageTitle, SectionTitle, Block, Note } from '@root/Sandbox'
import { ContentRow, PageContent } from "@root/ui/Layout"
import BoardsList from "."
import { BoardInfo } from "@root/types"

const ITEM: BoardInfo =           {
  active: true,
  board_id: 9999,
  date_from: "Jun 1st",
  date_to: "Aug 30th",
  name: "Collectors Club",
  num_requirements: 5,
  countdown_sec: 30000,
  num_requirements_fulfilled: 3,
  fulfilled: false,
  fulfillment_final: false
}

const Sandbox:React.FC = () => {
  return <>
    <PageTitle>Boards List</PageTitle>

    <PageContent>
    <Block>
      <ContentRow>
        <BoardsList selected={8888} boardGroup={"sandbox-group" as any} boardInfos={[
          {...ITEM, board_id: 8888},
          {...ITEM, fulfilled: true, num_requirements_fulfilled: 5},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
        ]}/>
      </ContentRow>
    </Block>
    <ContentRow>
      <Note>Needs to pre-scroll because selected item is far to the left</Note>
    </ContentRow>
    <Block>
      <ContentRow>
        <BoardsList selected={8888} boardGroup={"sandbox-group" as any} boardInfos={[
          {...ITEM},
          {...ITEM, fulfilled: true, num_requirements_fulfilled: 5},
          {...ITEM},
          {...ITEM},
          {...ITEM, board_id: 8888},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
          {...ITEM},
        ]}/>
      </ContentRow>
    </Block>
    </PageContent>
  </>
}

export default Sandbox
