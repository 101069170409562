import _ from "lodash"

import { Reducer } from "@reduxjs/toolkit"
import { Action } from "@root/actions"
import { State } from "@root/state"
import landingReducer from "@components/page/Landing/reducer"
import boardsReducer from "@components/page/Boards/reducer"
import popupReducer from "@components/Popup/reducer"

const initialState = {
  contractEthereum: null,
  provider: null,
  currentToken: null,
  tokens: []
}

const allReducers =  [
  landingReducer,
  boardsReducer,
  popupReducer,
]

const rootReducer: Reducer<State, Action> = (state, action) => {
  const stateClone = {...state}
  return _.reduce(allReducers, (prevState, reducer) => reducer(prevState, action), stateClone)
}

export default rootReducer