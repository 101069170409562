import { Action } from "@root/actions"
import { State } from "@root/state"
import _ from "lodash"

export default (state: State, action: Action) => {
  switch (action.type) {
    case "LANDING_INIT":
      return {
        ...state,
        landing: action.data
      }
  }
  return state
}
