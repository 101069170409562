import React from "react"
import _ from "lodash"

import "./LocaleSwitcher.scss"

const flagImgContext = require.context("@images", true, /flag--.*(png)$/)
const flagImg = (locale: string) => flagImgContext(`./flag--${locale.toLocaleUpperCase()}.png`)

export const LOCALES = [
  ["de", "Deutsch"],
  ["en", "English"],
]

const LocaleSwitcher: React.FC = () => {
  return <div className="neo__localeSwitcher">
    {_.map(LOCALES, ([locale, title]) => {
      return <a key={locale} href={`/?locale=${locale}`} title={title}><img src={flagImg(locale)} /></a>
    })}
  </div>
}

export default LocaleSwitcher
