import React from "react"

import { HeaderRaw } from "./"
import { PageTitle, SectionTitle, Block, Note } from '@root/Sandbox'
import { ContentRow } from "@root/ui/Layout"

const Sandbox:React.FC = () => {
  return <>
    <PageTitle>Header</PageTitle>

    <SectionTitle>None selected / home / ...</SectionTitle>
    <Block>
      <ContentRow>
        <HeaderRaw />
      </ContentRow>
      <ContentRow>
        <HeaderRaw activeNav="home" />
      </ContentRow>
      <ContentRow>
        <HeaderRaw activeNav="boards" />
      </ContentRow>
      <ContentRow>
        <HeaderRaw activeNav="helpAndFAQ" />
      </ContentRow>
    </Block>
  </>
}

export default Sandbox
