import React from "react"

import "./ProgressBar.scss"

const ProgressBar: React.FC<{total: number, amount?: number}> = ({amount, total}) => {
  const className = [
    "neo__progressBar",
    amount === total ? "neo__progressBar--completed" : "neo__progressBar--notCompleted",
    amount ? "" : "neo__progressBar--0"
  ].join(" ")

  const barPerc = total <= 0
    ? 0
    : (total === amount
      ? 100
      : Math.floor(((amount || 0) / total) * 100))

  return <div className={className}>
    <span className="neo__progressBar__bar" style={{width: `${barPerc}%`}}/>
  </div>
}

export default ProgressBar
