import { State, UserInfo } from "@root/state"
import { NeoFC } from "@root/types"
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import _ from "lodash"
import { Dispatch } from "@reduxjs/toolkit"
import { Action } from "@root/actions"
import "./AccountSection.scss"
import AccountButton from "./AccountButton"
import I18n from "i18n-js"
import { ShortWallet } from "@root/helper/wallet"

type AccountState =
  {
    type: "notLoggedIn"
    login(): void
  } |
  {
    type: "loggedIn"
    userInfo: UserInfo
    logout(): void
  }

export const AccountSectionRaw: NeoFC<{accountState: AccountState}> = ({accountState}) => {
  const className = [
    "neo__accountSection",
    accountState.type === "loggedIn" ? "neo__accountSection--loggedIn" : "",
    accountState.type === "notLoggedIn" ? "neo__accountSection--notLoggedIn" : "",
  ].join(" ")

  return <div className={className}>
    {accountState.type === "loggedIn"
      ? <>
          <div className="neo__accountSection__connectedWallet">
            <span className="neo__accountSection__connectedWallet__fullWallet">{accountState.userInfo.nickname}</span>
            <span className="neo__accountSection__connectedWallet__shortWallet"><ShortWallet wallet={accountState.userInfo.nickname} /></span>
          </div>
          <AccountButton isLogout onClick={accountState.logout}>{I18n.t("account_section.logout_action")}</AccountButton>
        </>
      : <>
          <AccountButton onClick={accountState.login}>{I18n.t("account_section.login_action")}</AccountButton>
          <div className="neo__accountSection__connectInfo">{I18n.t("account_section.login_info")}</div>
        </>}
  </div>
}

const AccountSection: NeoFC = () => {
  const state = useSelector<State, State>((st) => st)
  const dispatch = useDispatch<Dispatch<Action>>()

  const accountState: AccountState = state.generic.user
    ? {
        type: "loggedIn",
        userInfo: state.generic.user,
        logout() { dispatch({type: "ACCOUNT_LOGOUT"}) }
      }
    : {
        type: "notLoggedIn",
        login: async () => {
          dispatch({type: "ACCOUNT_LOGIN"})
        }
      }

  return <>
    <AccountSectionRaw accountState={accountState} />
  </>
}

export default AccountSection
