import React, { useEffect, useRef, useState } from "react"
import _ from "lodash"

export type MsgType = "default" | "error"

const __debuggings: {date: Date, msg: string, type: MsgType}[] = []

export const debugMsg = (...msgs: any[]) => {
  __addMsg("default", msgs)
}

export const debugError = (...msgs: any[]) => {
  __addMsg("error", msgs)
}

const __addMsg = (type: MsgType, messageObjecrts: any[]) => {
  let msgsAsString: string[] = []

  _.each(messageObjecrts, m => {
    if (_.isString(m)) {
      msgsAsString.push(m)
    } else {
      try {
        const json = JSON.stringify(m)
        msgsAsString.push(json)
      } catch(err) {
        msgsAsString.push(`${m}`)
      }
    }
  })

  __debuggings.push({ date: new Date(), msg: msgsAsString.join(", "), type})
}

export const DebugMessages: React.FC = () => {
  const [updateMe, setUpdateMe] = useState(__debuggings.length)
  const updateMeRef = useRef(updateMe)
  updateMeRef.current = updateMe
  useEffect(() => { // check for new messages, the update
    const interval = window.setInterval(() => {
      if (updateMeRef.current !== __debuggings.length) {
        setUpdateMe(__debuggings.length)
      }
    }, 500)
    return () => { //cleanup
      window.clearInterval(interval)
    }
  }, [])
  return <div style={{position: "relative", maxHeight: "300px", overflowY: "auto", overflowX: "auto"}}>
    <h2>Debug</h2>
    {_.map(_.reverse(__debuggings), (d, index) => {
      return <div key={index} style={{
          borderBottom: "1px solid #888"
        }}>
          <div style={{
            color: d.type === "error" ? "red" : "white"
          }}>{d.date.getHours()}:{d.date.getMinutes()}:{d.date.getSeconds()}</div>
          <div style={{

          }}>{d.msg}</div>
        </div>
    })}
  </div>
}
