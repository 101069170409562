import { NeoFC } from "@root/types"
import React from "react"
import I18n from "i18n-js"

import "./Landing.scss"
import { LandingState } from "./state"
import { useSelector, useDispatch } from "react-redux"
import { State } from "@root/state"
import { Action } from "@root/actions"
import { Dispatch } from "@reduxjs/toolkit"
import { Button, ContentRow, PageContent, PageTitle, SectionTitle } from "@root/ui/Layout"
import { boardsPath, howItWorksPath } from "@root/routes"
import WithAppearEffect from "@root/ui/WithAppearEffect"

import teaserSrc from "@images/landing__teaser.png"
import { marked } from "marked"
import fillWithBabelMarkdown from "@root/helper/fillWithBabelMarkdown"

interface LandingProps {
  landing: LandingState
  reload(): void
  coming_soon?: Boolean
}
export const LandingRaw: NeoFC<LandingProps> = ({ landing, reload, coming_soon }) => {
  return <>
    <PageTitle infoText={I18n.t("landing.subtitle")}>{I18n.t("landing.title")}</PageTitle>
    <PageContent>
      <WithAppearEffect effect="opacity" delaySec={.3} mode="always">
        <div className="neo__landing__teaser__holder">
          <div className="neo__landing__teaser">
            <ContentRow><img src={teaserSrc} /></ContentRow>
            <ContentRow className="neo__landing__desc" {...fillWithBabelMarkdown("landing.info--md", {boards_link: boardsPath()})} />
            {!coming_soon && <ContentRow className="neo__landing__desc">
              <Button href={boardsPath()}>{I18n.t("landing.action_view_boards")}</Button>
            </ContentRow>}
            <ContentRow className="neo__landing__desc">
              <a href={howItWorksPath}>{I18n.t("landing.action_view_how_it_works")}</a>
            </ContentRow>          
          </div>
        </div>
      </WithAppearEffect>
    </PageContent>
  </>
}

const Landing: NeoFC = () => {
  const landingState = useSelector<State, LandingState>((st) => st.landing)
  const comingSoon = useSelector((st: State) => st.coming_soon)

  const dispatch = useDispatch<Dispatch<Action>>()
  const reload = () => dispatch({type: "LANDING_RELOAD_ASYNC"})

  return <LandingRaw landing={landingState} reload={reload} coming_soon={comingSoon} />
}

export default Landing