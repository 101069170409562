import React from "react"

import { AccountSectionRaw } from "./"
import { PageTitle, SectionTitle, Block, Note } from '@root/Sandbox'
import { ContentRow } from "@root/ui/Layout"

const Sandbox:React.FC = () => {
  return <>
    <PageTitle>AccountSection</PageTitle>

    <SectionTitle>Logged in</SectionTitle>
    <Block>
      <ContentRow>
        <AccountSectionRaw accountState={{
          type: "loggedIn",
          userInfo: {auth_token: "123345", nickname: "Samus"},
          logout() {console.log("logout")}}}/>
      </ContentRow>
    </Block>

    <SectionTitle>Not logged in</SectionTitle>
    <Block>
      <ContentRow>
        <AccountSectionRaw accountState={{
          type: "notLoggedIn",
          login() {console.log("login!")}}}/>
      </ContentRow>
    </Block>
  </>
}

export default Sandbox
