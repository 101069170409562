import useWasInVisibleArea from "@root/helper/useWasInVisibleArea"
import { NeoFC } from "@root/types"
import _ from "lodash"
import React from "react"

import "./WithAppearEffect.scss"

type Effect = "slideUp" | "opacity" | "scaleUp"
type AppearEffectMode = "onlyIfInitiallyHidden" | "always"

export const useAppearEffectClassNames = (clsPrefix: string, mode: AppearEffectMode) => {
  const {ref, initDone, initiallyVisible, wasVisible} = useWasInVisibleArea()

  return {
    ref,
    appearEffectClassNames: [
      !initDone || !wasVisible ? `${clsPrefix}--hidden` : "",
      initDone && wasVisible ? `${clsPrefix}--visible` : "",
      initDone && wasVisible && (mode === "always" || !initiallyVisible) ? `${clsPrefix}--appear` : "",
    ].join(" ")
  }
}

const WithAppearEffect: NeoFC<{effect: Effect, mode: AppearEffectMode, delaySec?: number}> = ({children, effect, mode, delaySec}) => {
  const {ref, appearEffectClassNames} = useAppearEffectClassNames("neo__withAppearEffect", mode)
  const className = [
    "neo__withAppearEffect",
    `neo__withAppearEffect--${effect}`,
    appearEffectClassNames
  ].join(" ")
  return <div ref={ref} className={className} style={_.isNumber(delaySec) ? {animationDelay: `${delaySec}s`} : {}}>{children}</div>
}

export default WithAppearEffect
